import React, { useEffect, useState } from 'react'
import '../App.css';
import logo from '../assets/image/Hunt.png';
import search from "../assets/image/search_icon.png";
import menubar from "../assets/image/mobile_menu_icon.png";
import closemenu from '../assets/image/mobile_menu_close_icon.png';
import login from '../assets/Icons/Group 66325.png'
import { useUserAuth } from '../context/UserAuthContext'
import { useLocation } from 'react-router-dom'
const static_url = process.env.REACT_APP_CDN_STATIC;
const Header = (props) => {
    const location = useLocation();
    const [collection ,setcollection]=useState([])
    const [menu_state,setmenustate]=useState(true)
    const [input,setinput]=useState('')
    const [userid,setuserid]=useState('')
    const {allContents}=useUserAuth();
    const subscription_check = localStorage.getItem('subscriptionEnabled')
    //importing function 
    //for widgets
    const closemenubar=()=>{
        setmenustate(!menu_state)
    }
    const id = localStorage.getItem('id')  
    useEffect(()=>{ 
        if(id){
            setuserid(id)
        }
    },[])

    const filter_result=(data, key)=>{
        const title = data.filter(x=>x.test.title.toLowerCase().includes(key.toLowerCase()))
        if(key.length <= 0){
            setcollection([])
        }else{
            setcollection(title)
        }
    }
    const handlesearch=(e)=>{
        const val = e.target.value
        setinput(val)
        filter_result(allContents,val)
    }
   
const [search_view,setsearch]=useState(false)
return (
        <header>
        <a href="/"><img src={logo} alt="logo" className='logo'/></a>
        <div className="right_nav">
            <div className={!menu_state ? "header_btn deactive":"header_btn"}>
            { props.state ? 
                    <ul>
                    <li><a href={'/my-profile/'+`${userid}`} className="userh"><i className="fa fa-user"></i> Account</a></li>
                    </ul>
                : 
                <ul>
                    <li><a href= "/login"  className="ho"><img src={login}/></a></li> 
                </ul>}
            </div>
            <div className="header_search">
                <div className="toggle_search"  onClick={()=> {setsearch(!search_view);closemenubar()}}> 
                    <span><img src={search} alt=""/></span>Search
                </div>
                <div className={search_view ? 'toggle_search_open' :'open toggle_search_open'}>
                    <div className="container">
                        <div className="toggle_search_open_area">
                            <div className="toggle_close" onClick={()=> setsearch(!search_view)}></div>
                            <input type="text" placeholder="Search" value={input} onChange={handlesearch} name=""/>
                            <input type="submit" name=""/>
                        </div>

                        {/*for search result */}
                        <section className="product">
                            <div className="container">
                                <div className="product_category">
                                    <div className="product_item_list header">
                                    {
                                    collection.map( (y,index) => {
                                        return(
                                            <a  key={index} href={'/content/details/'+`${y.test.title}`} className="product_item">
                                                            <div className="product_item_image">
                                                                <img src={static_url+'/'+y.test.awsStaticResourcePath +'/'+y.test.portraitPosterIdNormal} alt=""/>
                                                                <div className="product_item_image_overlay">
                                                                    <div className="play_icon"></div>
                                                                </div>
                                                            </div>
                                                            <p>{y.test.title}</p>
                                            </a>
                                        )
                                    })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {!subscription_check ?  
            <a href="/subscriptions/plans" className={ location.pathname === '/subscriptions/plans' ? 'click_to_subsc active':'click_to_subsc'}> 
            <i className="fa fa-rocket"></i> Subscription</a>:''}

            <a href="/upcoming" className={ location.pathname === '/upcoming' ? 'click_to_subsc active':'click_to_subsc'}> Upcoming</a>
            <a href="/" className={ location.pathname === '/' ? 'click_to_subsc active':'click_to_subsc'}> Home</a>
            
            
            <div className='mobile_primary'>
                <img onClick={()=> closemenubar()}  src={menubar}/>
                <ul className={menu_state ? 'deactive': 'active'}>
                <img onClick={()=> closemenubar()} src={closemenu}></img>
                <li><a href="/">Home</a></li>
                {/*<li><a href="/upcomings">Upcoming</a></li>*/}
                <li><a href="#" onClick={()=>setsearch(!search_view)}>Search</a></li>
                <li><a href="/upcoming">Upcoming</a></li>
                <li>{!subscription_check  ?  
                <a href="/subscriptions/plans" className={ location.pathname === '/subscriptions/plans' ? 'click_to_subsc active':'click_to_subsc'}>Subscription</a>
                :''}</li>
                </ul>
            </div>
        </div>
        </header>
  )
}

export default Header