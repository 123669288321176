/*global gtag*/
import React,{useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Access} from '../../services/Access'
import { purchase,purchase_new,purchase_stripe,purchase_paytm } from '../../context/Eventlogger'
import { useUserAuth } from '../../context/UserAuthContext'
const Success_paytm = () => {
  let navigate = useNavigate()
  const {allPayments,allSubscriptions,logout}=useUserAuth()
  const userId = localStorage.getItem('id')
  const [confirm,setconfirm]=useState({message:'',data:{subscription_enddate:''}})
  const [flag,setflag]=useState( false )
  const search = new URLSearchParams(window.location.search)
  const orderId = search.get("orderid");
  const status = search.get("status")
  const message = search.get("message")
  const paymentId = search.get("paymentid")
  const packageId = search.get("packageid")
  const [amount_,setamount_]=useState('')
  useEffect(()=>{
    checkGateway()
    updateOrder(orderId,userId,status,paymentId,message)
  },[])
  const checkGateway=()=>{
    if(allPayments.length > 0){
      const data = allPayments[0].payment.filter(x=> x._id === paymentId)
      const package_ = allSubscriptions[0].subscription.filter(x=> x._id === packageId)
      if(Array.isArray(package_)){
        const data ={
          amount:package_[0].price[0].value/100,
          currency:package_[0].price[0].currency
        }
        setamount_(data)
      }
      //purchase_new(package_)
      if(data && data[0].name === 'Stripe'){
        purchase_stripe(data)
      }else if(data && data[0].name === 'Paytm'){
        purchase_paytm(data)
      }
    }
  }
  
  const updateOrder=(orderId,userId,status,paymentId,message)=>{
    //for successfull payment
    if(status === 'true'){
      purchase(orderId , paymentId)
      setflag(true)
      const data= {
        'orderId':orderId,
        'userId':userId
      }
      Access.post('/updateOrder',data)
      .then(res=>{
        setTimeout(()=>{
         confirmOrder(orderId,userId)
         },[2000])
         //null for stripe 
      })
    }
    //for failed transaction
    else{
      const data= {
        'orderId':orderId,
        'error':message
      }

      Access.post('/updateOrder',data)
      .then(res=>{
        //setTimeout(()=>{
         //confirmOrder(orderId,userId)
         //},[3000])
         //null for stripe 
      }).catch(err =>{
        if(err.response.status === 403){
          logout()
          navigate('/login/error?message=please login again , session expired !')
        }
      })
    }
  }
  const confirmOrder=(orderId,userId)=>{
    const param = {
      'orderId':orderId,
      'userId':userId
    }
    Access.post('/confirmOrder',param)
    .then(res=>{
      setprocess(!process)
      if(res.data.error === false){
        setflag(true)
        gtag('event', 'conversion', {
          'send_to': 'AW-366683598/uLIjCKDNprIYEM7L7K4B',
          'transaction_id': paymentId
        });
        purchase_new(amount_)
        setconfirm(res.data)
        epoch_to_normal(res.data.data.subscription_enddate)
        setTimeout(()=>{
          navigate("/my-profile/"+userId)
        },[5000])
      }else{
        setflag(false)
        setTimeout(()=>{
          navigate("/")
        },[5000])
      }
    }).catch(err =>{
      if(err.response.status === 403){
        logout()
        navigate('/login/error?message=please login again , session expired !')
      }
    })
  }


  const[date,setdate]=useState('')
  const epoch_to_normal=(time)=>{
    const daten = new Date(time)
    setdate(daten.toLocaleDateString() )
  }
const [process,setprocess]=useState(true)
  return (
    <>
    {
    process ? 
    <div className='container success'>
    <h1>Order Processing ! <br/>
    <span style={{'color':'#0099e3'}}>{orderId}</span>  </h1>
    
      Processing .... <br/>
      If you have any questions, please email
      <a href="mailto:huntcinemaofficial@gmail.com"> huntcinemaofficial@gmail.com</a>.
    <p className="mt-3" style={{'color':'red'}}>Please wait !! || Don't press the back button or close the window ...</p>
    </div>
    :
    <>
    {!flag ? 
    <div className='container success'>
    <h1>Order Failed ! <br/>
    <span style={{'color':'red'}}>{orderId}</span>  </h1>
    
      Payment Unsuccessfull ! 
      If you have any questions, please email
      <a href="mailto:huntcinemaofficial@gmail.com"> huntcinemaofficial@gmail.com</a>.

      <p>{confirm.message}</p>
      <p>{date}</p>
    

    <p className="mt-3" style={{'color':'red'}}>Please wait ! you will be redirected. if not <a href={"/my-profile/"+userId}>click here</a></p>
    </div>
    :
    <div className='container success'>
    <h1>Thanks for your Order! <br/>
     <span style={{'color':'green'}}>{orderId}</span> </h1>
    
      Payment Successfull ! 
      If you have any questions, please email
      <a href="mailto:huntcinemaofficial@gmail.com"> huntcinemaofficial@gmail.com</a>.

      <p>{confirm.message}</p>
      <p>{date}</p>
    

    <p className="mt-3" style={{'color':'red'}}>Please wait ! you will be redirected. if not <a href={"/my-profile/"+userId}>click here</a></p>
    </div>
    }
    </>}
    </>
  )
}

export default Success_paytm