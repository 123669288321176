import React from 'react'

const Newsletter = () => {
  return (
  <section className='trailernews'>
    <h1>JOIN OUR NEWSLETTER</h1>
    <p>Subscribe to our weekly newsletter and stay updated.</p>
    <input type="text" placeholder='Enter Full Name'></input>
    <input type="email" placeholder='Email Email Address'></input>
    <button className='playbtn'>Subscribe Now</button>
  </section>
  )
}

export default Newsletter