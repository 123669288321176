import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL
const apiKEY=process.env.REACT_APP_API_KEY;
const profile=process.env.REACT_APP_CDN_PROFILE;
var token =localStorage.getItem('access-token') ;
var userId = localStorage.getItem('id') 
var string ;
if(userId && token ){
    string = btoa(userId+':'+token) 
}
const Access = axios.create({
    withCredentials:true,
    baseURL: baseURL,
    headers:{
    "Content-Type": "application/json",
    "signature":"wRdD5BqZQv0s125JMqLvinTxB7o",
    "accesstoken":token
    }
});
const Updateinstance =axios.create({
    withCredentials: true,
    baseURL: baseURL,
    headers:{
    "userId":userId,
    "signature":"wRdD5BqZQv0s125JMqLvinTxB7o",
    }
});
const Accessinstance =axios.create({
    baseURL: profile,
    headers:{
    'Authorization': 'Basic ' + string
    }
});
const Token  = axios.create({
    baseURL : baseURL
})
export  {Access,Updateinstance,Accessinstance,Token};
