import React from 'react'
import download1 from "../../assets/Icons/download 2.svg"
import download2 from "../../assets/Icons/download 1.svg"
import bg  from '../../assets/Icons/s1.png';
const Download = () => {
  return (
    <>
    <section className="container add">
    <div className='row'>
        <div className='col-md-6'>
            <img src={bg}/>
        </div>
        <div className='col-md-6'>
            <h2>Download your shows to<br/> watch offline</h2>
            <p>Save your favourites easily and always have <br/>something to watch.</p>
            <hr/>
            <h3>Download Our App</h3>
            <div className='images'>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.huntcinema.android.pro"><img src={download2}/></a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.huntcinema.android.pro"><img src={download1}/></a>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default Download