import React,{useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
import play from '../assets/Icons/Play button.png'
import Download from './Contents/Download'
const static_url = process.env.REACT_APP_CDN_STATIC;
const Upcomingcontentdetails = () => {
let {contentId} = useParams();
const navigate = useNavigate();
const { allUpcomings } = useUserAuth();
const [persons, setPersons] = useState({seasons:[] , actors:[],cast:[]});
const [genre, setgenre] = useState([])
const [date,setdate]=useState('')

useEffect(() => {
    if(allUpcomings.length > 0){
        validate(allUpcomings)
    }
},[])

const validate=(res)=>{
    for(var i = 0; i<res.length;i++){
        if(res[i].id === contentId){     
            setPersons(res[i].test) 
            setgenre(res[i].test.genre)
            const dat= res[i].test.releaseDate//toLocaleString()
            const epoch = new Date(res[i].test.releaseDate)
            setdate(epoch.toLocaleDateString())
            
        }
    }
}
const playerRef = React.useRef();
const fullscreen=()=>{

    if (playerRef.current.requestFullscreen) {
        playerRef.current.requestFullscreen();
      } else if (playerRef.current.webkitRequestFullscreen) { /* Safari */
        playerRef.current.webkitRequestFullscreen();
      } else if (playerRef.current.msRequestFullscreen) { /* IE11 */
        playerRef.current.msRequestFullscreen();
      }
}
const assign=(contentId,title,url)=>{
    //upcoming_trailer();
    navigate('/trailer/play/'+title+'/url?&&id='+url+'&&contentId='+contentId+'&&type=upcoming_trailer')
  }
const [flag,setflag]=useState(false)
//https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8

  return (
    <section className='content-details'>
    <img src={static_url+'/'+persons.resourcePath+'/'+ persons.landscapeNormal} id="back"></img>
    <div className='container'>
    <div className='row'>
        <div className='col-md-12'>
            <img src={play} id="play" onClick={()=>assign(persons._id,persons.title , persons.mediaUrl.web)}></img>
            <div className={!flag ? 'content-back col-md-12 remove':'content-back col-md-12'}>
                <span className={!flag ? 'blur remove':'blur'}></span>
                <div className='col-md-6'>
                    <img src={static_url+'/'+persons.resourcePath+'/'+ persons.landscapeNormal}/>
                </div>
                <div className='col-md-6'>
                    <p>Hunt Original</p>
                    <h1>{persons.title}</h1>
                    <ul>
                        {genre.map(x=>{
                            return <li>{x}</li>
                        })}
                    </ul>
                    <p>{persons.description}</p>
                    <p>Release Date : {date}</p>
                    <p>Casts : {persons.cast.map((c,index) => { 
                    if( persons.cast.lastIndexOf(c) !== persons.cast.length-1 ){
                        return (
                            <span key={index}> { c + ' ,'}</span>
                            ) 
                    }else{
                        return (
                            <span key={index}>{' '+ c}</span>
                            )
                    }
                   })}</p>
                </div>
            </div>
        </div>
    </div>
    </div>
    <Download/>
    </section>
  )
}

export default Upcomingcontentdetails