import { createContext, useContext } from "react";
import {
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import Instance from "../services/Base"
import { Access } from "../services/Access"
import Dexie from 'dexie'
import { useLiveQuery } from "dexie-react-hooks";
import { login, sign_up } from "./Eventlogger";
import axios from "axios";
const userAuthContext = createContext();
const db = new Dexie('Hunt');
db.version(1).stores(
  {
    contents: 'id,content',
    posters: "++id,poster",
    categories: "++id,category",
    upcomings: "++id,upcoming",
    subscriptions: "++id,subscription",
    payments: '++id,payment',
  }
)
export function UserAuthContextProvider({ children }) {
  var qq = null;
  if (typeof window !== 'undefined'){
    qq = localStorage.getItem('subscriptionEnabled');
  }
  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }
  const login_with_whatsapp=async(data)=>{
    return Instance.post('/loginWithWhatsapp',data)
    .then(res=>{
      if(res.data.error === false && res.data.data){
        login('login_with_whatsapp')
        if(qq === null && res.data.data.subscription_enddate !== 0){
          localStorage.setItem('subscriptionEnabled',true)
        }
        localStorage.setItem('access-token',res.data.data.accessToken)
        localStorage.setItem('id',res.data.data.userId)
        refresh()
      }
      return res
    }).catch(err=>{
      console.log(err)
    })
  }
  const login_with_otp = async (data) => {
    return Instance.post('/loginWithOTP', data)
      .then(res => {
        if (res.data.error === false && res.data.data) {
          login('loginwithotp')
          if(res.data.data.subscription_enddate !== 0){
            qq === null ? localStorage.setItem('subscriptionEnabled',true) :<></>
          }else{
            localStorage.removeItem('subscriptionEnabled');
          }
          //localStorage.setItem('access-token',res.data.data.accessToken)
          localStorage.setItem('id', res.data.data.userId)
          refresh()
        }
        return res
      }).catch(err => {
        console.log(err)
      })
  }
  //login using email - lalten
  const loginwithemail = async (data) => {
    return Instance.post('/login', data)
      .then(res => {
        if (res.data.error === false) {
          login('Email')
          if(res.data.data.subscription_enddate !== 0){
            qq === null ? localStorage.setItem('subscriptionEnabled',true) :<></>
          }else{
            localStorage.removeItem('subscriptionEnabled');
          }
          //localStorage.setItem('access-token',res.data.data.accessToken)
          localStorage.setItem('id', res.data.data.userID)
          refresh()
        }
        //setError(res.data.message)
        return res;
      }).catch(err => {
        console.log(err)
      })
  }
  //registration API call
  const Signup = async (data) => {
    return Instance.post('/registration', data)
      .then(res => {
        return res;
      }).catch(err => {
        console.log(err)
      })
  }
  const refresh = () => {
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
  //logout api call
  const logout = async () => {
    signOut(auth);
    localStorage.clear();
    return Instance.post('/logout')
      .then(res => {
        if (res.status === 200) {
          return true
          //refresh()
        }
      }).catch(err => {
        console.log(err)
      })
    //refresh()
    //AddData()
  }

  const forgetpass = async (email) => {
    const data = {
      'email': email
    }
    const res = await Instance.post('/forgotPassword', data);
    return res.data.message;
  }

  const getContent = async () => {
    return Instance.get('/getContents?index=0&&limit=50')
      .then(res => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          return null
        }

      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          //refresh()
        }
      })
  }
  const getupcomingContent = async () => {
    return Instance.post('/getUpcoming')
      .then(res => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          return null
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
        }
      })
  }
  const allSubscriptions_marketing = async () => {
    const data = {
      "platform": "Android Phone",
      "countryCode": "in"
    }
    return Instance.post('/getAllPackages', data)
      .then(res => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          return null
        }
      })
  }

  const userid = localStorage.getItem('id')

  const getPackage = async () => {
    if (!userid) {
      return allSubscriptions_marketing()
    } else {
      const data = {
        "platform": "Android Phone",
        "countryCode": "in",
        "userId": userid
      }
      return Instance.post('/getPackages', data)
        .then(res => {
          if (res.status === 200 && res.data.code !== 1008) {
            return res.data.data;
          } else {
            return []
          }
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
          }
        })
    }

  }

  const getPayment = async () => {

    const data = {
      "status": "active"
    }
    return Instance.post('/listPaymentGateways', data)
      .then(res => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          return null
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
        }
      })
  }

  const AddData_filtered = async (arr, name) => {
    if (name === 'content') {
      arr.map(x => {
          return db.contents.put({
            id: x.id,
            test: x.test
          })
      })
    }
    if (name === 'upcoming') {
      arr.map(x => {
        return db.upcomings.put({
          id: x.id,
          test: x.test
        })
      })
    }
    if (name === 'poster') {
      arr.map(x => {
        return db.posters.put({
          id: x.id,
          poster: x.test
        })
      })
    }
    refresh()
  }

  const Delete_data = async (arr, name) => {
    if (name === 'content') {
      arr.map(x => {
        return db.contents.delete(x)
      })
    }

    if (name === 'upcoming') {
      arr.map(x => {
        return db.upcomings.delete(x)
      })
    }

    if (name === 'poster') {
      arr.map(x => {
        return db.posters.delete(x)
      })
    }
  }
  const getId=()=>{
    const id = localStorage.getItem('id')
    return id
  }
  //when component mounted(if empty)
  const AddData = async () => {
    const result = await getContent();
    const upcoming = await getupcomingContent();
    const subscription = await getPackage();
    const payment = await getPayment();
    if(result.contents.length > 0){
      result.contents.map(x => {
          return db.contents.add({
            id: x._id,
            test: x
          })
      })
      upcoming.map(x => {
        return db.upcomings.add({
          id: x._id,
          test: x
        })
      })
      //for posters
      result.promotionalPoster.map(x => {
        return db.posters.add({
          id: x._id,
          poster: x
        })
      })
      await db.categories.add({
        category: result.category
      })
      await db.subscriptions.add({
        subscription: subscription
      })
      await db.payments.add({
        payment: payment
      })
    }
    refresh()
  }
  const UpdateData = async (id, value, name) => {
    if (name === 'content') {
      await db.contents.update(id, { test: value })
    }
    if (name === 'upcoming') {
      await db.upcomings.update(id, { test: value })
    }
    if (name === 'category') {
      await db.categories.update(id, { category: value })
    }
    if (name === 'poster') {
      await db.posters.update(id, { poster: value })
    }
    if (name === 'payment') {
      await db.payments.update(id, { payment: value })
    }
    if (name === 'subscription') {
      await db.subscriptions.update(id, { subscription: value })
    }
    refresh()
  }

  const data_handler = async () => {
    /*return getAll().then(res => {
      return res;
    })*/
  }
  const allContents = useLiveQuery(() => db.contents.reverse().toArray(), []);
  //const testContents = useLiveQuery(() => db.test.toArray(), []);
  const allPosters = useLiveQuery(() => db.posters.toArray(), []);
  const allCategories = useLiveQuery(() => db.categories.toArray(), []);
  const allUpcomings = useLiveQuery(() => db.upcomings.toArray(), []);
  const allSubscriptions = useLiveQuery(() => db.subscriptions.toArray(), []);
  const allPayments = useLiveQuery(() => db.payments.toArray(), [])
  if (!allContents) return null
  if (!allPosters) return null
  if (!allCategories) return null
  if (!allUpcomings) return null
  if (!allSubscriptions) return null
  if (!allPayments) return null

  const googleSignIn = (e) => {
    e.preventDefault()
    const googleAuthProvider = new GoogleAuthProvider();
    const che = googleAuthProvider.addScope("email");
    signInWithPopup(auth, che)
      .then(res => {
        login('google')
        register_mobile(res.user, res.providerId)
      });
  }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn()
        .then(res => {
          register_mobile(res.user, res.providerId)
        })

    } catch (error) {
      console.log(error.message);
    }
  };


  function facebookSignIn() {
    const facebookAuthProvider = new FacebookAuthProvider();
    return signInWithPopup(auth, facebookAuthProvider);
  }
  const handleFacebookSignIn = async (e) => {
    e.preventDefault();
    try {
      await facebookSignIn()
        .then(res => {
          login('facebook')
          register_mobile(res.user, res.providerId)
        })

    } catch (error) {
      console.log(error.message);
    }
  };


  const register_mobile = async (user, providerId) => {
    var obj = user.providerData[0];
    var nm = obj.phoneNumber
    var ne = obj.displayName
    var profile = obj.photoURL
    var email = obj.email
    var permission;
    if (providerId === 'facebook.com') {
      permission = 'facebook'
    } else if (providerId === 'google.com') {
      permission = 'google'
    } else {
      permission = 'phone'
    }

    const data = {
      'name': !ne ? '' : ne,
      'email': !email ? '' : email,
      'password': '',
      'permission': permission,
      'mobile': !nm ? '' : nm,
      'profilePic': !profile ? '' : profile,
      'fireBaseAuthUserID': obj.uid,
    }
    const response = await Signup(data)
    if (response.data.error === false) {
      sign_up('Email')
      if(response.data.data.subscription_enddate !== 0){
        qq === null ? localStorage.setItem('subscriptionEnabled',true) :<></>
      }else{
        localStorage.removeItem('subscriptionEnabled');
      }
      localStorage.setItem('id', response.data.data.userId)
      localStorage.setItem('access-token',response.data.data.accessToken)
     refresh()
    }
    return response

  }

  //checking the content  of indexed db 


  const Reset = async (data) => {
    return Instance.post('/resetPassword', data).then(res => {
      return res.data
    })
  }
  const ChangePassword = async (data) => {
    return Access.post('/changePassword', data).then(res => {
      return res.data
    }).catch(err => {
      if (err.response.code === 403) {
        logout()
      }
    })
  }

  const Support = async (data) => {
    return Access.post('/createSupportTicket', data)
      .then(res => {
        return res.data
      })
  }
  const fetchticket=async(id)=>{
    const params ={
      uuid:id
    }
    return Instance.post('/getSingleSupportTicket',params)
    .then(res=>{
      return res
    }).catch(err=>{
      console.log(err)
    })
    
  }
  const player = async (data) => {
    return Access.post('/getplayInfoNew', data)
      .then(res => {
        return res
      }).catch(err => {
        if (err.response.code === 403) {
          logout()
        }
      })
  }

  const isValid = async (data) => {
    return Access.post('/isValid', data)
      .then(res => {
        return res
      }).catch(err => {
        console.log(err)
      })
  }
  //adding contrycode based on the users location
  const cc = localStorage.getItem('country_code')
  const setCountryCode = () => {
    if (!cc) {
      return axios.get('https://ipapi.co/json/').then((response) => {
        localStorage.setItem('country_code', response.data.country_code)
        return response
      }).catch(err => {
        console.log(err)
      })
    }

  }
  //fetching all the data from indexed db as well as from original server - Update Logic
  const fetch = async () => {
    //setting country code
    if (!cc) {
      setCountryCode()
    }
    const con = await getContent()
    const pay = await getPayment()
    if (allContents.length === 0 && con.contents.length > 0) {
     AddData()
    }

    //update logic indexed db
    if (con === undefined || pay === undefined) {
      console.log('Bad Gateway - 404')
    } else {
      // ---**All Contents - Update Code**---//
      if (allContents.length > 0) {
        //checking length of each array --- Indexed DB Data
        const content = allContents.length
        //---**Contents**---//
        if (content === con.contents.length) {
          for (var i = 0; i < con.contents.length; i++) {
            for (var j = 0; j < content; j++) {
              if (con.contents[i]._id === allContents[j].id) {
                const indexed = allContents[j].test
                const original = con.contents[i]
                if (indexed.title !== original.title || indexed.description !== original.description ||
                  indexed.director !== original.director || indexed.subcategory[0] !== original.subcategory[0] ||
                  indexed.production !== original.production || indexed.releaseDate !== original.releaseDate ||
                  indexed.trailerFileUrl[0] !== original.trailerFileUrl[0] || indexed.categoryName !== original.categoryName ||
                  indexed.ageGroup !== original.ageGroup || indexed.landscapePosterIdNormal !== original.landscapePosterIdNormal ||
                  indexed.landscapePosterIdSmall !== original.landscapePosterIdSmall || indexed.portraitPosterIdNormal !== original.portraitPosterIdNormal ||
                  indexed.portraitPosterIdSmall !== original.portraitPosterIdSmall) {
                  UpdateData(indexed._id, original, 'content')
                }


                //checking seasons array length
                if (indexed.seasons.length !== original.seasons.length || indexed.countryFiltered.length !== original.countryFiltered.length ||
                  indexed.deviceFiltered.length !== original.deviceFiltered.length || indexed.genre.length !== original.genre.length ||
                  indexed.actors.length !== original.actors.length) {
                  UpdateData(indexed._id, original, 'content')
                  break
                }

                //if webseries then checking episode name and other fields
                if (indexed.categoryName === 'Webseries' || indexed.categoryName === 'Series') {
                  //console.log('executing webseries -->', indexed.title)
                  indexed.seasons.map((x, index) => {
                    if (x.episodes.length !== original.seasons[index].episodes.length) {
                      //console.log('please update content episode changed length not matching')
                      UpdateData(indexed._id, original, 'content')
                    }
                    if (x.episodes.length === original.seasons[index].episodes.length) {
                      const ss = original.seasons[index]
                      x.episodes.map((y, index) => {
                        //console.log('season -->',x.seasonName,'episode -->', y.name)
                        if (y.name !== ss.episodes[index].name || y.description !== ss.episodes[index].description ||
                          y.duration !== ss.episodes[index].duration || y.landscapePosterId !== ss.episodes[index].landscapePosterId) {
                          //console.log('update episode name ->',y.name , 'updated value is ->',ss.episodes[index].name )
                          UpdateData(indexed._id, original, 'content')
                        }
                        const eps = ss.episodes[index]
                        if (y.casts.length !== eps.casts.length) {
                          UpdateData(indexed._id, original, 'content')
                        } else {
                          y.casts.map((z, index) => {
                            if (z !== eps.casts[index]) {
                              UpdateData(indexed._id, original, 'content')
                            }
                          })
                        }
                      })
                    }
                  }
                  )
                }

                //checking actors array 
                if (indexed.actors.length === original.actors.length) {
                  indexed.actors.map((x, index) => {
                    if (x !== original.actors[index]) {
                      UpdateData(indexed._id, original, 'content')
                    }
                  })
                }
                //checking seasons array length && fields 
                if (indexed.countryFiltered.length === original.countryFiltered.length) {
                  indexed.countryFiltered.map((x, index) => {
                    if (x !== original.countryFiltered[index]) {
                      UpdateData(indexed._id, original, 'content')
                    }
                  })
                }

                if (indexed.deviceFiltered.length === original.deviceFiltered.length) {
                  indexed.deviceFiltered.map((x, index) => {
                    if (x !== original.deviceFiltered[index]) {
                      UpdateData(indexed._id, original, 'content')
                    }
                  })
                }
                if (indexed.genre.length === original.genre.length) {
                  indexed.genre.map((x, index) => {
                    if (x !== original.genre[index]) {
                      UpdateData(indexed._id, original, 'content')
                    }
                  })
                }


                //boolean values censor freelyAvailable videoAvailable
                if (indexed.censor !== original.censor || indexed.freelyAvailable !== original.freelyAvailable ||
                  indexed.videoAvailable !== original.videoAvailable || indexed.published !== original.published) {
                  UpdateData(indexed._id, original, 'content')
                }


              }
            }
          }
        }
        //adding data if not available in the indexed db
        const arr_handler_add = []
        const allContents1 = allContents.map(x => x.id)
        const real = con.contents.map(x => x._id)

        if (allContents.length < con.contents.length) {
          con.contents.map(x => {
            if (allContents1.indexOf(x._id) === -1) {
              arr_handler_add.push({ id: x._id, test: x })
              AddData_filtered(arr_handler_add, 'content')
            }
          })
          //deleting data which are deleted from the database
          const arr_handler_delete = []
          if (allContents.length > con.contents.length) {
            allContents.map(x => {
              if (real.indexOf(x.id) === -1) {
                arr_handler_delete.push(x.id)
                Delete_data(arr_handler_delete, 'content')
              }
            })
          }
        }
      }

      //---**Category - Update Code**---//
      if (allCategories.length > 0) {
        const category = allCategories[0].category
        if (category.length > 0) {
          if (category.length !== con.category.length) {
            UpdateData(allCategories[0].id, con.category, 'category')
          } else {
            category.map((x, index) => {
              if (x.title !== con.category[index].title || x.description !== con.category[index].description) {
                UpdateData(allCategories[0].id, con.category, 'category')
              }
            })
          }
        }
      }


      // ---**Promotional Posters - Update Code**---//
      if (allPosters.length > 0 && con.promotionalPoster.length > 0) {
        const poster = con.promotionalPoster;

        const allPosters1 = allPosters.map(x => x.id)
        const real1 = poster.map(x => x._id)
        if (allPosters.length !== poster.length) {
          //two array to check data status
          //indexed data is more than the present data ----- delete operation -----
          if (allPosters.length > poster.length) {
            //deleteing the data if it is not present in the real database (present in the indexed db )
            const arr_handler_delete_poster = []
            allPosters.map(x => {
              if (real1.indexOf(x.id) === -1) {
                arr_handler_delete_poster.push(x.id)
                Delete_data(arr_handler_delete_poster, 'poster')
              }
            })
          }
          //indexed data for adding new upcoming content ----- add operation ------
          const arr_handler_add_poster = []
          poster.map(x => {
            if (allPosters1.indexOf(x._id) === -1) {
              arr_handler_add_poster.push({ id: x._id, test: x })
              AddData_filtered(arr_handler_add_poster, 'poster')
            }
          })
        } else {
          //when both the array length is equal
          for (var i = 0; i < poster.length; i++) {
            for (var j = 0; j < allPosters.length; j++) {
              if (poster[i]._id === allPosters[j].id) {

                var x = allPosters[j].poster
                if (x.name !== poster[i].name || x.url !== poster[i].url || x.awsResourceId !== poster[i].awsResourceId ||
                  x.link !== poster[i].link || x.source !== poster[i].source || x.ageRating !== poster[i].ageRating ||
                  x.type !== poster[i].type) {
                  UpdateData(x._id, poster[i], 'poster')
                }

                if (poster[i].tag.length !== x.tag.length) {
                  UpdateData(x._id, poster[i], 'poster')
                  break
                } else {
                  poster[i].tag.map((y, index) => {
                    if (y !== x.tag[index]) {
                      UpdateData(x._id, poster[i], 'poster')
                    }
                  })
                }
              }
            }
          }
        }
      }

      // ---**Payment Gateway - Update Code**--- //
      if(allPayments.length > 0){
        const payment = allPayments[0].payment
        if (payment.length > 0) {
          if (payment.length !== pay.length) {
            UpdateData(allPayments[0].id, pay, 'payment')
          } else {
            payment.map((x, index) => {
              if (x.name !== pay[index].name || x.logo !== pay[index].logo ||
                x.description !== pay[index].description || x.status !== pay[index].status) {
                UpdateData(allPayments[0].id, pay, 'payment')
              }
  
              const indexer = pay[index].paymentModes
              x.paymentModes.map((y, index) => {
                if (y !== indexer[index])
                  UpdateData(allPayments[0].id, pay, 'payment')
              })
            })
          }
        }
      }
    }
  }

  const Check_Upcoming = async () => {
    // ---**Upcoming Contents - Update Code**---//
    const upcon = await getupcomingContent()
    const upcoming = allUpcomings.length;
    if (allUpcomings.length > 0 || upcon.length > 0) {
      const allUpcomings1 = allUpcomings.map(x => x.id)
      const real1 = upcon.map(x => x._id)
      if (upcoming !== upcon.length) {
        //two array to check data status
        //indexed data is more than the present data ----- delete operation -----
        if (allUpcomings.length > upcon.length) {
          //deleteing the data if it is not present in the real database (present in the indexed db )
          const arr_handler_delete_upcoming = []
          allUpcomings.map(x => {
            if (real1.indexOf(x.id) === -1) {
              arr_handler_delete_upcoming.push(x.id)
              Delete_data(arr_handler_delete_upcoming, 'upcoming')
            }
          })
        }else{
          //indexed data for adding new upcoming content ----- add operation ------
          const arr_handler_add_upcoming = []
          upcon.map(x => {
            if (allUpcomings1.indexOf(x._id) === -1) {
              arr_handler_add_upcoming.push({ id: x._id, test: x })
              AddData_filtered(arr_handler_add_upcoming, 'upcoming')
            }
          })
        }
      } else {
        //when both the array length is equal
        for (var i = 0; i < upcon.length; i++) {
          for (var j = 0; j < allUpcomings.length; j++) {
            if (upcon[i]._id === allUpcomings[j].id) {
              var x = allUpcomings[j].test
              if (x.title !== upcon[i].title || x.categoryName !== upcon[i].categoryName || x.releaseDate !== upcon[i].releaseDate ||
                x.description !== upcon[i].description || x.landscapeNormal !== upcon[i].landscapeNormal || x.resourcePath !== upcon[i].resourcePath ||
                x.mediaUrl.web !== upcon[i].mediaUrl.web || x.mediaUrl.mobile !== upcon[i].mediaUrl.mobile || x.mediaUrl.common !== upcon[i].mediaUrl.common) {

                UpdateData(x._id, upcon[i], 'upcoming')

              }

              if (upcon[i].cast.length !== x.cast.length) {

                UpdateData(x.id, upcon[i], 'upcoming')
                break
              } else {
                upcon[i].cast.map((y, index) => {
                  if (y !== x.cast[index]) {
                    UpdateData(x._id, upcon[i], 'upcoming')

                  }
                })
              }
              //matched contents only 

            }
          }

        }
      }
    } //end here upcoming
  }

  const Check_Subscription = async () => {
    // ---**Subscriptions Packages - Update Code**---//
    if (allSubscriptions.length > 0) {
      const subscription = allSubscriptions[0].subscription
      //---**Subscription Packages**---//
      if (userid && allSubscriptions.length > 0) {
        const sub = await getPackage();
        if (sub && subscription) {
          if (subscription.length !== sub.length) {
            UpdateData(allSubscriptions[0].id, sub, 'subscription')
          } else {
            subscription.map((x, index) => {
              if (x.name !== sub[index].name || x.period !== sub[index].period ||
                x.platform !== sub[index].platform || x.status !== sub[index].status) {
                UpdateData(allSubscriptions[0].id, sub, 'subscription')
              }
              //benefits
              const indexer = sub[index].benefits
              x.benefits.map((y, index) => {
                if (y !== indexer[index]) {
                  UpdateData(allSubscriptions[0].id, sub, 'subscription')

                }

              })
              //price of package
              const indexer_price = sub[index].price
              x.price.map((z, index) => {
                if (z.value !== indexer_price[index].value || z.country !== indexer_price[index].country) {
                  UpdateData(allSubscriptions[0].id, sub, 'subscription')
                }

              })
            })
          }
        }

      }
      if (!userid && allSubscriptions[0].subscription.length === 0) {
        const sub = await allSubscriptions_marketing()
        UpdateData(allSubscriptions[0].id, sub, 'subscription')
      }
    }
  }

  const verify = async (token) => {
    const params = {
      token: token
    }
    return Instance.post('/verifyrecaptcha', params)
      .then(res => {
        return res
      }).catch(err => {
        return err
      })
  }
  return (
    <userAuthContext.Provider
      value={{
        fetch,
        isValid,
        data_handler,
        googleSignIn,
        setUpRecaptha,
        loginwithemail,
        getContent,
        handleFacebookSignIn,
        handleGoogleSignIn,
        Signup,
        logout,
        register_mobile,
        forgetpass,
        Reset,
        ChangePassword,
        allContents,
        allPosters,
        allUpcomings,
        allCategories,
        allSubscriptions,
        allSubscriptions_marketing,
        getupcomingContent,
        getPackage,
        getPayment,
        AddData,
        UpdateData,
        allPayments,
        Support,
        AddData_filtered,
        Delete_data,
        player,
        Check_Upcoming,
        Check_Subscription,
        login_with_otp,
        verify,
        refresh,
        login_with_whatsapp,
        fetchticket,
        getId
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}