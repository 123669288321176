import React, {useState,useEffect}  from 'react'
import 'react-phone-number-input/style.css';
import { useUserAuth } from '../context/UserAuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={8} ref={ref} variant="filled" {...props} />;
});
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const Login = () => {
    const {handleFacebookSignIn, login_with_otp , googleSignIn,loginwithemail,forgetpass,verify,login_with_whatsapp} = useUserAuth();
    const [user,setuser]= useState('');
    const [password,setpassword] = useState('');
    const [permission,setpermission]=useState('voovi');
    //const [otp, setotp]=useState('')
    var otp =[]
    const [number , setnumber]=useState('+91-')
    const [error,setError]=useState('')
    const [usercheck, setusercheck]= useState(false)
    const [flag1,setflag1]=useState(false)
    const [flag,setflag]=useState(true)
    const search = new URLSearchParams(window.location.search)
    const message = search.get("message");
    const [loader,setloader]=useState(false)
    const [forget,setforget]=useState(false)
    useEffect(()=>{
      if(message && message !== ''){
        setOpen(true)
      }

      //otpless
      const script = document.createElement("script");
      script.src = "https://otpless.com/auth.js";
      document.body.appendChild(script);
      const otplessInit = Reflect.get(window, 'otplessInit');
      otplessInit?.();

    Reflect.set(window, 'otpless', otpless);
    return () =>{
      document.body.removeChild(script);
    }
    },[])
    const otpless = (otplessUser) => {
      // your_code_here
      const token = otplessUser.token;
      if(token){
        const cred = {            
              token:token
        }
        login_with_whatsapp(cred)
    }
    }
    var pattern = new RegExp(/^\d{10}$/);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

   //validating its email sign in or mobile sign in using input value 
   const emaillogin=(e)=>{
    e.preventDefault()
    setloader(true)
    const exp = !!regex.test(user)
    if(user === ''){
      setloader(false)
      setError('please fill the mandatory fields*')
    }
    else{
      setloader(false)
      if(usercheck){
        if(!exp){
          setError('please enter a valid email*')
        }else{
          const data = {
          email: user,
          password:password,
          permission:permission
        }
          loginwithemail(data).then(res => {
            if(!res){
              setError('something went wrong')
            }else{
              setError(res.data.message)
            }
            }) 
        }
      }else{
        loginwithphone()
      }
      }
}
  const loginwithphone =async ()=>{
    //+91-8087736382
    setloader(!loader)
    var no = number.split('-')
    if(number.length === 14 && pattern.test(no[1])){
      if(usercheck && recap){
        setError('')
        const params = {
          phoneNumber:number,
          platform:'Web'
        }
        const data = await login_with_otp(params)
        setError(data.data.message) 
        setflag1(true)
        if(data.data.code === 0){
          setflag1(true)
        } else{
          setflag1(false)
        } 
        setloader(false)
      }else{
        setError('Please check terms and condition & privacy policy or resolve recaptcha')
        setloader(false)
      }
    }else{
      setError('Please enter a valid number') 
      setloader(false)
    }
  }

  const verifyOtp = async (e) => {
    e.preventDefault()
    setloader(true)
    var str = ''
    otp.forEach(input => {
        str += input
    });
    if (otp === "" || otp === null) {
      setloader(false)
      setError("Please enter a valid OTP!");
    }
    else{
      const params = {
        phoneNumber:number,
        platform:'Web',
        otp: otp[0]+otp[1]+otp[2]+otp[3]+otp[4]
      }
      const data = await login_with_otp(params)
      setError(data.data.message)
      setloader(false)
    }
  };

 const [check,setcheck]=useState(false)
  const handleforgetpassword=()=>{
    setcheck(!check)
    setuser('')
  }
  const forgetpassword=async (e)=>{
    e.preventDefault()
    if(user !== ''){
      const alert = await forgetpass(user)
      setError(alert)
    }
  }
  
 const handlechange=(e)=>{
  setnumber(e.target.value)
 }
 const handlechangeemail=(e)=>{
  setuser(e.target.value)
 }
 const handleotp=(e,index)=>{
  const target = e.target;
  if(target.value === ''){
    otp.splice(index,1)
    if(target.previousElementSibling){
      target.previousElementSibling.focus()
    }
  }else{
    otp[index] = e.target.value;
    //setotp([...otp,e.target.value])
    if(target.nextElementSibling){
      target.nextElementSibling.focus()
    }
  } 
 }
 const vertical = 'bottom'
const horizontal = 'center'
const [open, setOpen] = React.useState(false);
const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};
const [recap,setrecap]=useState(false)
const handler=async (e)=>{
  if(e === null){
      setrecap(false)
      setError('recaptcha verfication unsuccessfull, please try agian later.')
  }else{
  const result = await verify(e);
  if(result.data.data.success){
      setrecap(true)
  }else{
      setrecap(false)
  }
  }
}
  return (
    <section className="login">
      <div className={ loader ? 'loader' : 'loader hide'}><div className='spin'/></div>
      <Snackbar open={open} autoHideDuration={12000} onClose={handleClose} style={{'marginTop':'200px'}}anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {!flag ? 
      <>
      {!flag1 ?
      <div className='logg'>
        <p style={{'textAlign':'center','color':'red'}}>{error}</p>
        <h1>LOGIN</h1>
        <p>Welcome Back, Login and explore now !</p><br/>
        <input type="text" placeholder="Enter your phone number" value={number} onChange={handlechange} maxLength={14}/>
        <div className='flexbox'>
        <input type="checkbox" onChange={(e)=>setusercheck(e.target.checked)}/>
        <p>I confirmed that I am over 18+ and I agree to the <a href="/termsandcondition">Temrs and Condition</a> and <a href="/privacypolicy">Privacy Policy</a>.</p>
        </div>
        <ReCAPTCHA className='recap' sitekey={SITE_KEY} onChange={handler}/>
        { !loader ? <button className='playbtn' onClick={()=>loginwithphone()}>Send OTP</button>:<div className='loader'><div className='spin'/></div>}
        <p className='hr'>or continue with</p>
        <div className='social-icons'>
          <i className="fa fa-phone"></i>
          <i className='fa fa-google' onClick={googleSignIn}></i>
          <i className='fa fa-facebook' onClick={handleFacebookSignIn}></i>
        </div>
        <p>Don't Have an account ? <a href="/register">Sign Up Now !</a></p>
      </div>:
      <div className='logg'>
        <p style={{'color':'#fff','textAlign':'center'}}>{error}</p>
        <h1>VERIFY</h1>
        <p>Verification code sent to phone number {number}</p><br/>
        <div className='otp'>
          <input type="text" maxLength={1} onChange={(e)=>handleotp(e,0)} name="otp"/>
          <input type="text" maxLength={1} onChange={(e)=>handleotp(e,1)} name="otp"/>
          <input type="text" maxLength={1} onChange={(e)=>handleotp(e,2)} name="otp"/>
          <input type="text" maxLength={1} onChange={(e)=>handleotp(e,3)} name="otp"/>
          <input type="text" maxLength={1} onChange={(e)=>handleotp(e,4)} name="otp"/>
        </div>
        <button className='playbtn' onClick={verifyOtp}>Verify</button>
        <p>Didn't recieve the code ?</p>
        <div className='social-icons'>
          <i className="fa-solid fa-phone"></i>
          <i className='fa fa-google' onClick={googleSignIn}></i>
          <i className='fa fa-facebook' onClick={handleFacebookSignIn}></i>
        </div>
      </div>}
      </>
      :
      <>
      {!forget ?
      <div className='logg'>
      <p style={{'textAlign':'center','color':'red'}}>{error}</p>
      <h1>LOGIN</h1>
      <p>Welcome Back, Login and explore now !</p><br/>
      <input type="text" placeholder="Enter your email address" value={user} onChange={handlechangeemail}/>
      <input type="password" placeholder="Enter your password" value={password} onChange={(e)=>setpassword(e.target.value)}/>
      <div className='flexbox'>
        <input type="checkbox" onChange={(e)=>setusercheck(e.target.checked)}/>
        <p>I confirmed that I am over 18+ and I agree to the <a href="/termsandcondition">Temrs and Condition</a> and <a href="/privacypolicy">Privacy Policy</a>.</p>
        </div>
        <ReCAPTCHA className='recap' sitekey={SITE_KEY} onChange={handler}/>
      <p style={{'padding':'10px','color':'#0099e3'}} onClick={()=>setforget(!forget)}>Forget password ?</p>
      {!usercheck ? <button className='playbtn' disabled onClick={emaillogin}>Login</button>:
      <button className='playbtn' onClick={emaillogin}>Login</button>}
      <div id="otpless"></div>
      <p className='hr'>or continue with</p>
      <div className='social-icons'>
        <i className="fa fa-phone"></i>
        <i className='fa fa-google' onClick={googleSignIn}></i>
        <i className='fa fa-facebook' onClick={handleFacebookSignIn}></i>
      </div>
      <p>Don't Have an account ? <a href="/register">Sign Up Now !</a></p>
      </div>:
      <div className='logg'>
        <p style={{'textAlign':'center','color':'red'}}>{error}</p>
        <h1>Forget Password</h1>
        <p onClick={()=>setforget(!forget)}><i className='fa fa-arrow-left'></i> Back</p><br/>
        <input type="text" placeholder="Enter your email address" value={user} onChange={handlechangeemail}/>
        <button className='playbtn' onClick={forgetpassword}>Submit</button>
        <p className='hr'>or continue with</p>
        <div className='social-icons'>
          <i className="fa fa-phone"></i>
          <i className='fa fa-google' onClick={googleSignIn}></i>
          <i className='fa fa-facebook' onClick={handleFacebookSignIn}></i>
        </div>
        <p>Don't Have an account ? <a href="/register">Sign Up Now !</a></p>
      </div>
      }
      </>
      }
    </section>
  )
}

export default Login