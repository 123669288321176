import React,{useState,useEffect} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { Pagination,Navigation } from "swiper";
// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useUserAuth } from '../../context/UserAuthContext'
import { click_poster } from '../../context/Eventlogger';
// import required modules
import { EffectCards } from "swiper";
const static_url = process.env.REACT_APP_CDN_STATIC;
// Import Swiper styles
// Import Swiper styles
// Import Swiper styl
const Homeslider = () => {
  const {allContents} = useUserAuth()
  const [index,setindex]=useState(0)
  const [container,setContainer]=useState({test:{}})
  const [data,setdata]=useState([]);
  var new_arr=[];
  useEffect(()=>{
    if(allContents.length >0){
      allContents.map(x=>{
        if(x.test.ageGroup < 18){
          new_arr.push(x)
        }
      })
      setContainer(new_arr[0])
      setdata(new_arr)
    }
  },[])
  const appenddata=(e)=>{
    setindex(e.realIndex)
    setContainer(data[e.realIndex])
  }
  return (
    <div className='homeslider'>
       <div className="detailed">
            <p className='head'>HUNT <span>Original</span></p>
            <h2>{container.test.title}</h2>
            <p>{container.test.description}</p>
            <div className='buttons'>
            <button className="playbtn"><a href={`/content/details/${container.test.title}`}><i className="fa fa-play"></i> <span>Play Now</span></a> </button>
            <button className="watchbtn"><i className="fa fa-plus"></i> <span>Add watchlist</span> </button>
            </div>
          </div>
    <Swiper
      spaceBetween={10}
      effect={"cards"}
      grabCursor={true}
      navigation={true} 
      modules={[EffectCards,Navigation]}
      onSlideChange={(e) => appenddata(e)}
    >
      {data.map((x,index)=>{
        if(x.test.ageGroup < 18){
          return(
            <SwiperSlide key={index} >
              <a href={'/contents/'+`${x.test.title}`}>
              <img src={static_url +'/' +x.test.awsStaticResourcePath+'/'+x.test.landscapePosterIdNormal}/>
              </a>
            </SwiperSlide>
          )
        }
        
      })}
    </Swiper>
    <div className='bottom'>
      <p>{ index+1 } /{data.length}</p>
      <span className='line'></span>
    </div>
    {/*
    
     <SwiperSlide key={index} >
            { x.poster.link === 'https://' ? 
            <a target="_blank" href={`${x.poster.source}`} onClick={()=>click_poster()}>
            <img src={static_url +'/' +x.test.awsStaticResourcePath+'/'+x.test.landscapePosterIdSmall}/>
            </a>:
            <Link to={'/content/details/'+`${x.poster.link}`} onClick={()=>click_poster()}>
            <img src={static_url +'/' +x.test.awsStaticResourcePath+'/'+x.test.landscapePosterIdSmall}/>
            </Link>
            }
            
          </SwiperSlide>*/}
    </div>
      
  )
}

export default Homeslider