import React,{useEffect,useState} from 'react'
import { Link , useParams} from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'
import {Access} from "../../services/Access";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import paytmlogo from '../../assets/Payment/paytm_logo.png'
import stripelogo from '../../assets/Payment/stripe_logo.png'
import Upilogo from '../../assets/Payment/upi_logo.png'
import Gpay from '../../assets/Payment/gpay_logo.jpg'
import paypal from '../../assets/Payment/PayPal.png'
import postscribe from 'postscribe';
import { begin_checkout, checkout_paytm,checkout_stripe } from '../../context/Eventlogger';
const Listpaymentgateway = () => {
    //const stripePromise = loadStripe("pk_test_51Lzz9ZSEU05kM9NVQiBCEKlJYHT6mxDdyghad0baJAFZndaytzsOldVRltN0P6KEde9e5iH92l6U5ufoBAVkOOcE00X6zmq29h");
    const stripePromise = loadStripe("pk_live_51Lzz9ZSEU05kM9NVaYfCKeVyWostQ2IAU50CHbClb7jbHTaC3UsiUaDvlG9NLGKPampXp3tQGYdptefcNFmAKg4Q009RJdxq0W");
    const {allPayments,allSubscriptions,logout}=useUserAuth()
    const {packageId} = useParams()
    const userId = localStorage.getItem('id')
    const [paymentId,setpaymentId]=useState('')
    const [response,setresponse]=useState({})
    useEffect(()=>{
        const data = allSubscriptions[0].subscription.filter(x=> x._id === packageId)
        setresponse(data)        
    },[])

    const [flag,setflag]=useState(false)
    const [orderId,setorderId]=useState('')
    const [handle_paytm,sethandle_paytm]=useState({})

    const createOrder=(param,id,name)=>{
        setpaymentId(id)
        const filter_price = param[0].price
        const data = {
          amount: filter_price[0].value,
          currency: filter_price[0].currency,
          packageId:packageId,
          platform: param[0].platforms,
          userId:userId,
          countryCode:filter_price[0].countryCode,
          paymentGatewayId:id
        }
        
        begin_checkout(filter_price[0].value , filter_price[0].currency)
        if( name === 'Stripe' ){
          checkout_stripe(filter_price[0].value , filter_price[0].currency)
            Access.post('/createOrder',data)
            .then(res=>{
                if(res.data.error === false){
                setflag(true)
                setClientSecret(res.data.data.stripePlainPaymentIntent)
                setorderId(res.data.data.orderId)
                }
            })
        }else if( name === 'Paytm'){
          checkout_paytm(filter_price[0].value , filter_price[0].currency)
            Access.post('/createOrder',data)
            .then(res=>{
                if(res.data.error === false){
                  setflag(true)
                  sethandle_paytm(res.data.data)
                  setorderId(res.data.data.orderId)
                  setoption(!option)
                  //handleLoadScript(res.data.data)
                }
            })
        }
      } 

      

//for paytm creating js element 
      async function handleLoadScript(mode_selected,data) {
        const orderId1 = data.orderId;
        const txntoken = data.paymtxnToken;
        const amount = data.amount
        
        postscribe('#upi', '<script src="https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/HUNTCI80399412987017.js"></script>', {
          done: function() {
            if(window.Paytm && window.Paytm.CheckoutJS){
             
              //Add callback function to CheckoutJS onLoad function
              window.Paytm.CheckoutJS.onLoad(function  excecuteAfterCompleteLoad() {
                //Config
                var config = {
                  flow:"DEFAULT",
                  hidePaymodeLabel: false,
                  data:{
                    orderId:orderId1,
                    amount:amount,
                    token:txntoken,
                    tokenType:"TXN_TOKEN"
                  },
                  merchant:{
                    mid:"HUNTCI80399412987017"
                  },
                  handler: {
                    notifyMerchant: function(eventType, data) {
                      
                    }
                  }
                };
          
                //Create elements instance
                var elements = window.Paytm.CheckoutJS.elements(config);
                //Create card element
                if(mode_selected === 'UPI'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.UPI, {root: "#upi", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'NB'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.NB, {root: "#netbanking", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'PAY_WITH_PAYTM'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.PAY_WITH_PAYTM, {root: "#paywithpaytm", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'SCAN_AND_PAY'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.SCAN_AND_PAY, {root: "#scanandpay", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                
                //Render element
                
              });
            }
            
          }})
        
      };
      const modechange=(mode)=>{
        //setmode(name
        handleLoadScript(mode,handle_paytm)
      }


      //callback - https://laltenstaging.xyz/capture/paytm/callback
      const manage=(id,name)=>{        
        createOrder(response,id,name)
      }
      const cancleorder=()=>{
        const data= {
          orderId:orderId,
          paymentId:paymentId,
          userId:userId,
          error:'user cancled'
        }
        Access.post('/updateOrder',data)
        .then(res=>{
          setflag(false) 
          setoption(false)//null for stripe 
        }).catch(err=>{
          if(err.response.code === 403){
            logout()
          }
        })
    
      }
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    const [option,setoption]=useState(false)

  return (
    <div className='container payment'>
        
        {
          !option ?
          flag ? 
            <div className='row p-2'>
                <p style={{'padding':'10px','display':'inlineFlex','color':'#fff'}}><i className='fa fa-arrow-left' onClick={()=>cancleorder()}/> Back</p>
                 {clientSecret && (
                    <Elements className="mt-2" options={options} stripe={stripePromise}>
                    <CheckoutForm packageId={packageId}  orderId={!orderId?'':orderId}  paymentId={paymentId}/>
                    </Elements>
                )}
                
               
            </div>
            :
            <div className='row'>
                
                <Link to={'/subscriptions/plans/'+userId}><p style={{'color':'#fff','fontSize':'22px','padding':'10px'}}>Back <i className='fa fa-angle-right'></i></p></Link>
                { allPayments.length > 0 && allPayments[0].payment.map((x,index)=>{
                return(
                  <>
                        {x.logo === 'Stripe' || x.logo === 'Paytm' ? //Upilogo
                        <div className="inner" key={index}>
                        <img src={ x.logo === 'Stripe' ? stripelogo : x.logo === 'Gpay' ? Gpay : x.logo === 'Paytm' ? paytmlogo : x.logo ==='Paypal' ? paypal : Upilogo } onClick={()=> manage(x._id,x.name)}/>
                        </div>:
                        ''}
                        </>
                )
            })}
          </div>
          :
          ''
        }
        {
          !option ? 
          ''
          :
          <>
          <p style={{'padding':'10px 0px','display':'inlineFlex','color':'#fff','fontSize':'22px'}}><i className='fa fa-arrow-left' onClick={()=>cancleorder()}/> Back</p>
          <div className='paytm_option'>
            <img src={paytmlogo}></img>
            <ul>
              {/*<li onClick={()=> modechange('CARD')}><i className='fa fa-credit-card'></i> CREDIT / DEBIT CARD </li>
              <div id="card"></div>*/}
              <li onClick={()=> modechange('UPI')}><i className='fa fa-mobile-alt' style={{'fontSize':'20px'}}></i> UPI </li>
              <div id="upi"></div>
              <li onClick={()=> modechange('SCAN_AND_PAY')}><i className='fa fa-qrcode'></i> SCAN AND PAY</li>
              <div id="scanandpay"></div>
              <li onClick={()=> modechange('PAY_WITH_PAYTM')}><i className='fa fa-credit-card'></i> PAY WITH PAYTM</li>
              <div id="paywithpaytm"></div>
              <li onClick={()=> modechange('NB')}><i className='fa fa-university'></i> NET BANKING</li>
              <div id="netbanking"></div>
            </ul>
            
          </div>
          </>
        }
    </div>
  )
}

export default Listpaymentgateway