import React,{useState,useEffect,useRef} from 'react'
import '../../App.css';
import 'react-phone-number-input/style.css';
import Question from '../Contents/Question';
import { useUserAuth } from '../../context/UserAuthContext';
import ReCAPTCHA from "react-google-recaptcha";
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const Contactus = () => {
    const [name,setname]=useState('')
    const [email,setemail]=useState('')
    const [number,setnumber]=useState('')
    const [messaged,setmessaged]=useState('')
    const [subject,setsubject]=useState('')
    const [message,setmessage]=useState('')
    const [flag,setflag]=useState(false)
    const { Support, verify , refresh} = useUserAuth();
        
    const contact=async (e)=>{
        e.preventDefault();
        const data = {
            "email": email, 
            "mobile": number, 
            "name": name, 
            "issueType": subject, 
            "description": messaged         
        } 
        console.log(data)
        const result = await Support(data)
        if(result.code === 0){
            setTimeout(()=>{
               // refresh()
            },[2000])
        }
        setmessage(result.message)
    }
      const handler=async(e)=>{
        console.log(e)
        if(e === null){
            setflag(false)
            setmessage('Recaptcha not verfied !!')
        }else{
            const result = await verify(e);
            console.log(result)
            if(!result.data.data.success){
                setmessage('something went wrong')
            }else{
                setflag(true)
                setmessage('')  
            }
        }
      }
  return (
    <>
    <div className='container contactus'>
        <h1>Contact Us</h1>
        <p>Hunt offers you a wide collection of movies and shows to choose from in HD.</p>
                <form onSubmit={contact}>
                <p style={{'fontSize':'16px' ,'marginBottom':'0px','color':'orange','padding':'0px'}}>{message}</p>
                    <div className="ch">
                    <label>Full Name</label><br/>
                    <input type="text" placeholder='Enter Your Full Name' onChange={(e)=> setname(e.target.value)}></input>
                    </div>
                    <div className="half">
                    <div className="ch">
                    <label>Email Address</label><br/>
                    <input type="text" placeholder='Enter Your Email Address'  onChange={(e)=> setemail(e.target.value)}></input>
                    </div>
                    <div className="ch">
                    <label>Phone Number</label><br/>
                    <input type="text" placeholder='Enter Your Phone Number'  onChange={(e)=> setnumber(e.target.value)}></input>
                    </div>
                    </div>
                    <div className="ch">
                    <label>Your Message</label><br/>
                    <textarea  rows="5" placeholder='Type Your Message'  onChange={(e)=> setmessaged(e.target.value)}/>
                    </div>
                    <ReCAPTCHA
                    className='m-3'
                        sitekey={SITE_KEY}
                        onChange={handler}
                    />
                    {flag  ?
                    <button className='playbtn' type='submit'>Submit Now</button>
                    :
                    <button className='playbtn' disabled type='submit'>Submit Now</button>
                    }               
                </form>
                <div className='companyd'>
                <p>Address : Hunt Cinema Private Limited, C/O PRAKASH VEER JANSATH ROAD; ALMASPUR; MUZAFFARNAGAR; Muzaffarnagar; Uttar Pradesh; 251001</p>
                <p>Contact Number : +91 9870771816</p>
                <p>Support Email : huntcinemaofficial@gmail.com</p>
                </div>
                <h2><i className='fa fa-phone'></i> Toll Free : +91 9870771816</h2>
    </div>
    <Question/>
    </>
  )
}

export default Contactus