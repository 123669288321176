import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAqP7AnD3oT6CEEdO0K3qHeD9kMnWNEw2g",
  authDomain: "play-integrity-vune5hbcyonwje8.firebaseapp.com",
  projectId: "play-integrity-vune5hbcyonwje8",
  storageBucket: "play-integrity-vune5hbcyonwje8.appspot.com",
  messagingSenderId: "978098267882",
  appId: "1:978098267882:web:c2d480fea3e08814831993",
  measurementId: "G-QGCYET2WLG"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth= getAuth(app);
//export default app;