import React,{useState,useEffect,useRef} from 'react'
import { useParams } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext';
const static_url = process.env.REACT_APP_CDN_STATIC;
const Categories = () => {
  const scrl = useRef()
  const { allContents } = useUserAuth();
  let {categoryName , categoryId} = useParams();
  const [filtered,setfiltered]=useState([])
  
    useEffect(()=>{
      callback()
    },[])
    
    const callback=()=>{
      const filtered1 = allContents.filter( x => x.test.categoryName === categoryName)
      setfiltered(filtered1)
    }
    
    const slide = (shift) => {
      scrl.current.scrollLeft += shift;
    };
  return (
  <div className='container category'>
  <section className="product">
    <div className="container">
        <div className="product_category">
            <div className="product_category_heading">
                <h4>{categoryName}</h4>
                
            </div>
            <div className="product_item_list" ref={scrl}>
           
               
                        <div>
                        {filtered.map((y,index)=>{
                            return(
                                <a  key={index}  href={'/content/details/'+`${y.test.awsStaticResourcePath}`+'/'+`${y.test._id}`} className="product_item">
                                    <div className="product_item_image">
                                        <img src={static_url+'/'+y.test.awsStaticResourcePath +'/'+y.test.portraitPosterIdNormal} alt=""/>
                                        <div className="product_item_image_overlay">
                                            <div className="play_icon"></div>
                                        </div>
                                    </div>
                                    <p>{y.test.title}</p>
                                </a>
                            )
                        })}
                        </div>
               
           
            </div>
         </div>
    </div>
</section>
    </div>
  )
}

export default Categories