import React, { useState,useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthContext'
import ruppee from '../assets/image/membership_cut_price.png'
import Download from '../components/Contents/Download'
import Question from '../components/Contents/Question';
import { useNavigate } from 'react-router-dom';
const Allpackages = () => {
  const { allSubscriptions_marketing,allSubscriptions,getId,fetch} = useUserAuth();
  const [array,setarray]=useState([])
  const navigate = useNavigate();
  const subscription_check = localStorage.getItem('subscriptionEnabled')
  const id = localStorage.getItem('id')
  useEffect(()=>{
    const pack=async()=>{
      const data = await allSubscriptions_marketing()
      setarray(data)
      if(allSubscriptions && allSubscriptions.length  === 0){
        fetch()
      }
      if(subscription_check){
        navigate(`/my-profile/${id}`)
      }
      //extract(data)
    }
    pack()
  },[])

  return (
    <>
    <div className='container subs'>
        <div className='row'>
          <div className='col-md-12 sep'>
             {array.map((x,index) =>{
            return(
                <div className='col-md-3 package' key={index}>
                  <p className='per'> {x.price.map(z => { return(<>  <img src={ruppee}/> { z.value/100 } </>)})}</p> 
                    <p>{x.name} - {x.period}</p>
                    <div className='listb'>
                    {x.benefits.map((x,index)=>{
                      return <li key={index}><i className='fa fa-check'></i> {x.benifitsName}</li>
                    })}
                    </div>
                    <a href={'/subscriptions/payments/modes/active/'+ x._id}><button className='btn_buy'> BUY NOW</button> </a>
                </div>
            )
           })}
          </div>
          
           
           
        </div>
        
    </div>
    <br/>
    <Download/>
    <Question/>
    </>
  )
}

export default Allpackages