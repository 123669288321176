import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext';
const Ticket = () => {
const {fetchticket} = useUserAuth();
const [loader,setloader]=useState(false)
const [data,setdata]=useState({})
const [err,seterr]=useState('')
const {id} = useParams();
useEffect(()=>{
  fetchticket(id).then(res=>{
    if(!res.data.error){
      setdata(res.data.data)
    }else{
      seterr('something went wrong')
    }
  })
},[])

return (
  <div className='ticket'>
    <div className={ loader ? 'loader' : 'loader hide'}>
      <div className='spin'/>
    </div>
    <h1>Ticket Details :</h1>
    <div className='ticket-table'>
    <table className="table table-light">
  <thead>
    <tr>
      <th scope="col">Ticket No.</th>
      <th scope="col">Email</th>
      <th scope="col">Number</th>
      <th scope="col">Issue</th>
      <th scope="col">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">{data.ticketNumber}</th>
      <td>{data.email}</td>
      <td>{data.mobile}</td>
      <td>{data.issueType}</td>
      <td>{data.status}</td>
    </tr>
  </tbody>
</table>
<p style={{'color':'red','padding':'10px'}}>{err}</p>
    </div>

  </div>
)
}

export default Ticket