import React,{useEffect} from 'react';
import Trailer from './Slider/Trailer';
import Download from './Contents/Download';
import Upcomingslider from './Slider/Upcomingslider';
import { useUserAuth } from '../context/UserAuthContext';
const Upcoming = () => {
  const {Check_Upcoming} = useUserAuth()
  useEffect(()=>{
    Check_Upcoming()
  },[])
  return (
    <>
    <section className="banner">
    <div className="banner_col2">
    <Upcomingslider/>
    </div>
    </section>
    <section className='banner trailer'>
    <h1>TOP 12 MOVIE THIS MONTH</h1>
    <p>watch top 12 movie this month</p>
        <div className='banner_col'>
        <Trailer/>
        </div>
    </section>
    <Download/>
    </>
  )
}

export default Upcoming