import React,{useState,useRef,forwardRef} from "react";
import './Player.css'
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
  const controlsWrapper =  {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "row",
    color: '#ccc',
    zIndex: 1,
    margin: '0px 0%',
    padding: '10px',

  }

  const bottomIcons = {
    color: "#ccc",
    bottom: '45%',
    left:'25%',
    right:'25%',
    display:'inline-flex',
    position:'absolute',
    width: '50%',
    alignContent:'center',
    justifyContent:'center'
  }
  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }
  
  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.number.isRequired,
  };
const PrettoSlider = styled(Slider)({
  color: '#d90917',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 18,
    background: 'unset',
    padding: 0,
    width: 70,
    height: 50,
    borderRadius: '0% 0% 0% 0',
    backgroundColor: '#d90917'
  },
});


const PlayerControlstrailer = forwardRef(({
  onPlayPause,
  playing,
  onforward,
  onbackward,
  onmute,
  muted,
  Buffer,
},ref) => {
  return (
    <>
    <div ref={ref}>
    <div style={bottomIcons}>
    {Buffer ? 
      <CircularProgress />
    :
    ''}
    {/*<>
    <i className="fa fa-backward" onClick={onbackward}></i>
    {playing ? <i className="fa fa-pause" onClick={onPlayPause}></i>:<i className="fa fa-play"  onClick={onPlayPause}></i>}
    <i className="fa fa-forward" onClick={onforward}></i>
    </>*/}
    </div>

    <div className="bottom container" style={{'height':'50px','padding':'5px 10px 0px 10px','position':'absolute','bottom':'0','backgroundColor': 'rgba(0,0,0,0.6)'}}>
        {muted ?<i className="fa fa-volume-mute" onClick={onmute}></i> : <i className="fa fa-volume-up" onClick={onmute}></i> }
      </div>
    </div>
    </>
    
  )
});
export default PlayerControlstrailer