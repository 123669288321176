import React,{useRef,useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { AppBar } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import PlayerControls from "./PlayerControls";
import screenfull from "screenfull";
import './Player.css'
import { watch_trailer } from '../context/Eventlogger';
import { SnowshoeingOutlined } from "@mui/icons-material";
import Hls from "hls.js";
const playback = process.env.REACT_APP_CDN_PLAYBACK;
var count = 0;
function Test() {
  let {id} = useParams()
  const title = "Big Bunk Bunny"
  const playerRef = useRef()
  const playerContainerRef= useRef()
  const controlRef = useRef()
  const [hlsUrl,sethlsUrl]=useState('')
  const [sub,setsub]=useState(false)
  const [trackLang, setTrackLang] = useState('');
  const [quality,setquality]=useState(1744)

 {/* useEffect(()=>{
   
    //sethlsUrl(playback+ id + '/trailer_h264.smil/playlist.m3u8')
    const textTracks = playerRef.current.getInternalPlayer()?.textTracks;
    console.log(textTracks)
    
    for (var i = 0; textTracks?.length && i < textTracks.length; i++) {
      // For the 'subtitles-off' button, the first condition will never match so all will subtitles be turned off
      if (textTracks[i].language == trackLang) {
        textTracks[i].mode = "showing";
      } else {
        textTracks[i].mode = "hidden";
      }
    }
  },[trackLang])*/}
  
  {/*const source = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8';
  const hit=()=>{
    console.log('hitted')
    if(Hls.isSupported()){
      const hls = new Hls();
      hls.loadSource(source);
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        // Transform available levels into an array of integers (height values).
        console.log(hls.levels)
        console.log(hls.currentLevel)
        hls.levels.map((level, levelIndex) => {
          console.log(level.height)
          console.log(quality)
          if (level.height == quality) {
            console.log("Found quality match with " + quality);
            hls.currentLevel = levelIndex;
            console.log(hls.currentLevel)
          }
        });
       
  })
      
      
    
    }

  }*/}
 


  const [state,setstate] = useState({
    playing:false,
    muted:true,
    playbackRate:1.0,
    played:0,
    seeking:false,
    volume: 1,
    loop: true,
    pip:false
  })
  const [buff,setBuff]=useState(false)
  const fullscreen=()=>{
    
  }
  const handleplaypause=()=>{
    setstate({...state, playing: !state.playing})

  }
  const handleforward=()=>{
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
  }
  const handlebackward=()=>{
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
  }
  const handlemute=()=>{
    setstate({...state, muted:!state.muted})
  }

  const handleplaybackratechange=(rate)=>{
    setstate({...state, playbackRate : rate })
  }

  const toggleFullScreen=()=>{
    screenfull.toggle(playerContainerRef.current)
  }
  const handleBuffer=()=>{
    setBuff(true)
  }
    const handleBufferEnd=()=>{
    setBuff(false)
  }


  const handleProgress=(changeState)=>{
    if(count > 3){
      controlRef.current.style.visibility="hidden"
      count = 0
    }
    if(controlRef.current.style.visibility === 'visible'){
      count+=1
    }
    if(!state.seeking){
      setstate({...state, ...changeState})
    }

  }

  const handleSeekChange=(e,newValue)=>{
    setstate({...state,played:parseFloat(newValue/100)})
  }
  const handleSeekMouseDown=(e)=>{
    setstate({...state,seeking:true})
  }
  const handleSeekMouseUp=(e,newValue)=>{
    setstate({...state,seeking:false})
    playerRef.current.seekTo(newValue/100)
  }

    const format=(seconds)=>{
      if(isNaN(seconds)){
        return '00:00'
      }
      const date = new Date(seconds*1000)
      const hh = date.getUTCHours()
      const mm = date.getUTCMinutes()
      const ss = date.getUTCSeconds().toString().padStart(2,"0")
      if(hh){
        return `${hh}:${mm.toString().padStart(2,"0")}:${ss}`
      }
      return `${mm}:${ss}`
    }
  const currentTime = playerRef.current ? 
  playerRef.current.getCurrentTime() : '00:00'

  const duration = playerRef.current ? 
  playerRef.current.getDuration() : '00:00'

  const elapsedTime = format(currentTime)
  const totalDuration = format(duration)

  const handleMouseMove=()=>{
    controlRef.current.style.visibility="visible";
    count = 0
  }
  const handleVolumeChange=(e, newValue)=>{
    setstate({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });

  }
  const handleVolumeSeekDown=(e, newValue)=>{
    setstate({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  }



  const handleQuality=(quality , level)=>{
    console.log(quality,level)
    //console.log(level)
     {/*if(Hls.isSupported()) {
      console.log('yes')
        var hls = new Hls();
        // bind them together
        hls.attachMedia(playerRef);
            hls.loadSource("https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8");
            hls.on(Hls.Events.MANIFEST_PARSED, function(event,data) {
              console.log('yes1')
              hls.startLoad()
                hls.autoLevelEnabled = false;
                hls.loadLevel = 1;
                playerRef.current.play();
            })
        
    }*/}
    setstate({...state, playing: false})
    
    var hls = playerRef.current.getInternalPlayer('hls');
    hls.currentLevel = quality
    hls.subtitleTrack = level
    //console.log(alllevel)
    //setstate({...state, playing: !state.playing})
    setstate({...state, playing: true})
  }
  const onTrackChange=(e)=>{
    //console.log(e)
    setTrackLang(e)
  }
  const handlePip=()=>{
    setstate({...state, pip:!pip})
  }

  const{playing,muted,playbackRate,played,seeking,pip,volume}=state  
  
  //https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8
  //http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4
  return (
    <>
        <div className="playerWrapper" 
        ref={playerContainerRef}
        onMouseMove={handleMouseMove}>
          <ReactPlayer
            width="100%"
            height="100%"
            url="https://dxpbjqh4tapqb.cloudfront.net/voovi_9/playlist.m3u8"
            muted={muted}
            playing={playing}
            pip={pip}
            ref={playerRef}
            playbackRate={playbackRate}
            onProgress={handleProgress}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
          />
          
        <PlayerControls 
        playerRef={playerRef}
        ref={controlRef}
        onPlayPause={handleplaypause}
        playing={playing} 
        onforward={handleforward}
        onbackward={handlebackward}
        muted={muted}
        onmute={handlemute}
        playbackRate={playbackRate}
        onplaybackratechange={handleplaybackratechange}
        onToggleFullScreen={toggleFullScreen}
        played={played}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        onVolumeChange={handleVolumeChange}
        onVolumeSeekDown={handleVolumeSeekDown}
        elapsedTime={elapsedTime}
        totalDuration={totalDuration}
        Buffer={buff}
        onBuffer={handleBuffer}
        onBufferEnd={handleBufferEnd}
        title={title}
        onQuality={handleQuality}
        onTrack={onTrackChange}
        onPip={handlePip}
        //onDetail={handlePlayer}
        />
        </div>
{/* config={{
              file: {
                attributes: {
                  crossOrigin: "anonymous"
                },
                tracks: [
                  {
                    kind: "subtitles",
                    src:
                      "https://raw.githubusercontent.com/benwfreed/test-subtitles/master/mmvo72166981784.vtt",
                    srcLang: "en",
                    default: sub,
                    mode: trackLang === "en" ? "showing" : "hidden"
                  },
                  {
                    kind: "subtitles",
                    src:
                      "https://gist.githubusercontent.com/samdutton/ca37f3adaf4e23679957b8083e061177/raw/e19399fbccbc069a2af4266e5120ae6bad62699a/sample.vtt",
                    srcLang: "it",
                    default: false,
                    mode: trackLang === "it" ? "showing" : "hidden"
                  }
                ]
              }
            }} */}
    </>
  );
}

export default Test;