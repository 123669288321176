import React ,{useState} from 'react'
import { useUserAuth } from '../context/UserAuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const Register = () => {
    const {handleFacebookSignIn,googleSignIn, Signup,verify,login_with_otp} = useUserAuth();
    /*patterns for validation*/
    var pattern = new RegExp(/^[0-9\b]+$/);
    var regularExpression = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    /*use states variables*/
    const [passwordalert,setpasswordalert]=useState({pass:'',match:'',dob:''})
    const [name,setname]=useState('')
    const [emailinput,setemail]=useState('')
    const [passwordinput,setpassword]=useState('')
    const [confirmp,setconfirmp]=useState('')
    const [gender,setgender]=useState('')
    const [dob, setdob] = useState({})
    const [check,setcheck]=useState(false)
    const [error,setError]=useState('')
    const [flag,setflag]=useState(false)
    const [datevalid,setdatevalid]=useState(false)

    const register=(e)=>{
        e.preventDefault();
        registerwithemail()
        //usercheck ?  registerwithemail() : registerwithphone()
    }
    const handleuser =(e)=>{
        let email_val = e.target.value
        if(email_val !== ''){
            setemail(email_val)
        }
        //if(email_val.length === 10){
            //pattern.test(e.target.value) ?  logic(e) : setusercheck(true);
        //}
    }

    const handlepassword=(e)=>{
        let pass_val = e.target.value
        /*regularExpression.test(e.target.value) ? setpassword(e.target.value) : validation()*/
        if(pass_val !== ''){
           setpassword(pass_val)
           setpasswordalert({pass:''})
        }
    }
    const handleconfirmpassword=(e)=>{
        setconfirmp(e.target.value)
    }
    const handledate=(e)=>{
        setdob(e.target.value)
        const d = e.target.value;
        const  ch = d.split('-').join('/')
        ValidateDOB(ch)
    }

    function ValidateDOB(date) {
        var dateString = new Date(date);
        var curr_date =new Date();
        var take_dateString = dateString.getUTCFullYear();
        var take_curr_date = curr_date.getUTCFullYear();
        const diff = take_curr_date - take_dateString ;
        if(diff < 18){
            setdatevalid(true)
            setpasswordalert({dob:'Age must be greater that 18+'})
        }else{
            setdatevalid(false)
            setpasswordalert({dob:''})
        }

    }

    const handlegenre=(e)=>{
        setgender(e.target.value)
    }

    const handlename=(e)=>{
        setname(e.target.value)        
    }
    const handle=(e)=>{
        setcheck(e.target.checked)
    }
     //registeration API
     const registerwithemail=async()=>{
        const exp = !!regex.test(emailinput)
        if(check !== true){
            setError('please accept terms and condition')   
        }
        if(name === ''){
            setError('Name is mandatory')
        }
        if(exp === false) {
            setError('Enter a valid email ')
        }
        if(!regularExpression.test(passwordinput)){
            setpasswordalert({pass:'Password must contain minimum 1 unique special character and 1 numeric value.'})
        }
        if( passwordinput !== confirmp ){
            setpasswordalert({match:'Confirm Password not matching'})
        }
        
        if(check === true  && regularExpression.test(passwordinput) && passwordinput === confirmp && exp===true && !datevalid){
            setpasswordalert({pass:'',match:'',dob:''})
            const dateToday = new Date(dob)
            const birth = Date.parse(dateToday)
            const data = {
                name: name,
                email: emailinput,
                password: passwordinput,
                permission: 'hunt',
                dob:birth,
                userGenre:gender
                }
            const response = await Signup(data)
            setError(response.data.message)
        }else{
            setError('please fill up all the mandatory details')
        }
    }  
    
    const handler=async (e)=>{
        if(e === null){
            setflag(false)
            setError('recaptcha verfication unsuccessfull, please try agian later.')
        }else{
        const result = await verify(e);
        if(result.data.data.success){
            setflag(true)
        }else{
            setflag(false)
        }
        }
    }
  return (
<section className="login">
    <div className='logg'>
      <p style={{'color':'#fff','textAlign':'center','color':'red'}}>{error}</p>
      <h1>REGISTER</h1>
      <p>Welcome, Register and explore now !</p><br/>

    
      <input type="text" placeholder="Enter your name" value={name} onChange={handlename}/>
      <input type="text" placeholder="Enter your email address" onChange={handleuser}/>

      <div className="dat">
        <div className='dat1'>
        {!datevalid ?'':<small className='small'>{passwordalert.dob}</small> }
        <input type="date" className="form-control" onChange={handledate} name=""/>
        </div>
        <div className='dat1'>
        <input className="radio" name="gender" id="M" type="radio" value="M"  onChange={handlegenre}/>
        <label className="label">Male</label> 
        <input className="radio" name="gender" id="F" type="radio" value="F" onChange={handlegenre}/>
        <label className="label">Female</label> 
        </div>
      </div>
      <small className='small'>{passwordalert.pass}</small>
      <input type="password" placeholder="Enter your password" onChange={handlepassword}/>
      <small className='small'>{passwordalert.match}</small>
      <input type="password" placeholder="Re-enter your password" onChange={handleconfirmpassword}/>
      <div className='flexbox'>
        <input type="checkbox" onChange={handle}/>
        <p>I confirmed that I am over 18+ and I agree to the <a href="/termsandcondition">Temrs and Condition</a> and <a href="/privacypolicy">Privacy Policy</a>.</p>
        </div>
        <ReCAPTCHA className='recap' sitekey={SITE_KEY} onChange={handler}/>

      { !check && !flag ? <button className='playbtn' disabled onClick={register}>Register</button>:<button className='playbtn' onClick={register}>Register</button>}



      <p className='hr'>or continue with</p>
      <div className='social-icons'>
        <i className='fa fa-google' onClick={googleSignIn}></i>
        <i className='fa fa-facebook' onClick={handleFacebookSignIn}></i>
      </div>
      <p>Have an account ? <a href="/login">Login Now !</a></p>
      </div>
</section>
  )
}

export default Register