import React from 'react'
const Privacypolicy = () => {
  return (
    <section class="policy">
    <div class="container">
        <div class="policy_area">
            <div class="policy_area_col">
            <div class="policy_heading">
                <h2>Privacy <span>Policy</span></h2>
            </div>
            <p>Thank you for choosing HUNT CINEMA PRIVATE LIMITED. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal 
                information when you use our mobile application (the "App"). By accessing or using the App, you agree to the terms of this Privacy Policy.</p>
            
            <div class="policy_heading">
                    <h2>Information We Collect</h2>
            </div>
            <p>We may collect personal information that you voluntarily provide to us when you register with the App, participate in surveys or contests, 
                or communicate with us. The types of personal information we may collect include your name, email address, and any other information you 
                choose to provide.</p>

            <div class="policy_heading">
                    <h2>Use of Your Information</h2>
            </div>
            <p>We may use the personal information we collect from you to:</p><br/>
            <br/>
            <p>
            - Provide, maintain, and improve the App<br/>
            - Respond to your comments, questions, and requests<br/>
            - Send you technical notices, updates, security alerts, and support and administrative messages<br/>
            - Communicate with you about promotions, events, and other news about the App<br/>
            - Monitor and analyze trends, usage, and activities in connection with the App<br/>
            - Personalize and improve the App and provide advertisements, content, or features that match user profiles or interests<br/>
            </p>
    
            <div class="policy_heading">
                    <h2>Disclosure of Your Information</h2>
            </div>
            <p>We do not share your personal information with third parties for their marketing purposes. However, we may disclose your personal information to:</p>
            <p>- Third-party service providers who perform services on our behalf, such as hosting, data analysis, payment processing, customer service, and marketing activities. 
                These service providers are authorized to use your personal information only as necessary to provide these services to us.</p>
            <p>- Business partners with whom we may collaborate to offer products or services. In such cases, your personal information will only be shared with your consent.</p>
            <p>- Law enforcement, government authorities, or other third parties if we believe that disclosure is necessary to comply with a legal obligation, protect our rights, 
                investigate fraud, or respond to a government request.</p>
            <div class="policy_heading">
                    <h2>Data Security</h2>
            </div>
            <p>We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure. 
                Therefore, we cannot guarantee absolute security.</p>

            <div class="policy_heading">
                    <h2>Children's Privacy</h2>
            </div>
            <p>The App is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, 
                please contact us, and we will delete such information from our systems.</p>

            <div class="policy_heading">
                    <h2>Changes to this Privacy Policy</h2>
            </div>
            <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the "Effective Date" and, if required by applicable law, obtaining your consent.</p>

            <div class="policy_heading">
                    <h2>Contact Us</h2>
            </div>
            <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <b style={{'color':'red'}}>huntcinemaofficial@gmail.com</b></p>
        
            <p>By using the <b style={{'color':'red'}}>HUNT CINEMA PRIVATE LIMITED</b>, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use,
            and disclosure of your personal information as described herein.</p>
    </div>
</div>
</div>
</section>
  )
}

export default Privacypolicy