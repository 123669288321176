import React,{useState,useEffect} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useUserAuth } from '../../context/UserAuthContext'
const static_url = process.env.REACT_APP_CDN_STATIC;
const Trailer = () => {
  const {allContents} = useUserAuth()
  const [flag,setflag]=useState(false)
  const [data,setdata]=useState([]);
  var new_arr=[];
  useEffect(()=>{
    if(window.innerWidth < 600){
      setflag(true)  
    }
    if(allContents.length >0){
    allContents.map(x=>{
      if(x.test.ageGroup < 18){
        new_arr.push(x)
      }
    })
    setdata(new_arr)
  }
  },[])
  
  return (
    <Swiper
      loop={true}
      slidesPerView={!flag ? 4 : 1}
      pagination={{
        clickable: true,
      }}
      navigation={true} 
      modules={[Pagination,Navigation]}
      spaceBetween={20}
    >
       {data.map((x,index)=>{
        return(
          <SwiperSlide key={index} >
            <a href={'/contents/'+`${x.test.title}`}>
            <img src={static_url +'/' +x.test.awsStaticResourcePath+'/'+x.test.landscapePosterIdSmall}/>
            </a>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default Trailer