import React,{useEffect,useState,useRef} from 'react'
import { useNavigate, useParams} from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
import Select from 'react-select';
import Download from './Contents/Download';
import ruppee from '../assets/image/membership_cut_price.png'
import TrailerPlayback from '../Player/TrailerPlayback';
const static_url = process.env.REACT_APP_CDN_STATIC;
const playback = process.env.REACT_APP_CDN_PLAYBACK;
const Contentdetails = () => {
const [selectedOption, setSelectedOption] = useState(null);
let {contentId} = useParams();
const scrl = useRef()
const bannerbox = useRef(null);
useOutsideAlerter(bannerbox)
const navigate = useNavigate();
const userId = localStorage.getItem('id');
const {allContents,allSubscriptions,fetch} = useUserAuth()
const [persons, setPersons] = useState({seasons:[] , actors:[],trailerFileUrl:[],genre:[],actors:[]});
const [genre, setgenre] = useState([]);
const [options ,setoptions]=useState([]);
const [season ,setseason]=useState([])
const [child,setchild]=useState({episodes:[]})
const [flag,setflag]=useState(true)
const [date,setdate]=useState('')
const [seas,setseas]=useState(false)
const [awsresourceId,setawsresourceId]=useState('')
const [episode,setepisode]=useState({})
const [loader,setloader]=useState(true)
const [switcher,setswitcher]=useState(false)
const [hidebannerbox,sethidebannerbox]=useState(false)
const [playing,setplaying]=useState(true)
const [array,setarray]=useState([])
const [trailerUrl,setrailerUrl]=useState({})
const subscription_check = localStorage.getItem('subscriptionEnabled')

useEffect(() => { 
    if(allContents.length > 0){
        validate(allContents)
        setloader(false)
    }else{
        fetch()
    }
    const pack=async()=>{
        if(allSubscriptions.length >0 && allSubscriptions[0].subscription){
            setarray(allSubscriptions[0].subscription)
        }
      }
    pack()
},[])

const validate=(res)=>{
    for(var i = 0; i<res.length;i++){
        if(res[i].test.title === contentId){
            setPersons(res[i].test) 
            const path = res[i].test.trailerFileUrl;
            setrailerUrl(`${playback}${path[0]}/trailer.m3u8`)
            setswitcher(true)
            setgenre(res[i].test.genre)
            setawsresourceId(res[i].test.awsStaticResourcePath)
            setseason(res[i].test.seasons)
            const dat= res[i].test.releaseDate.toLocaleString()
            setdate(dat)
            if(res[i].test.seasons.length <= 0){
                setflag(false)
            }else{
                setepisode(res[i].test.seasons[0].episodes[0])
                setseas(true)
                setoptions(res[i].test.seasons.map(x => {return  {'value': x._id, 'label': x.seasonName}}))
                setSelectedOption({'value':res[i].test.seasons[0]._id,'label':res[i].test.seasons[0].seasonName})
                seasoncheck(res[i].test.seasons[0]._id,res[i].test.seasons)
            }
        }
    }
}
const seasoncheck=(seasonId,season)=>{
        var i;
        for(i=0 ; i < season.length;i++){
            if(season[i]._id === seasonId){
                setchild(season[i])
                if(season[i].episodes.length === 0){
                    setmsg('')
                }else{
                    setmsg('')
                }
            }
        }
}
const [msg,setmsg]=useState('No seasons & Episodes')
const handle=(e)=>{
setSelectedOption(e)
seasoncheck(e.value,season)
}


const assign=(path,title)=>{
const pathnew = path[0]
navigate('/trailer/play/'+title+'/url?contentId='+contentId +'&&id='+ pathnew)
}
const handleepisode=(x)=>{
    navigate('/episode/play/'+x.name+'/url?userId='+userId+'&&'+'contentId='+contentId+'&&episodeId='+x._id+'&&type=episode_playback' )
}
//https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8
const slide = (shift) => {
    scrl.current.scrollLeft += shift;
};

const handleradio=(e)=>{
    console.log(e.target.value)
}

function useOutsideAlerter(ref) {
    useEffect(() => {
   
      // Function for click event
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          sethidebannerbox(true)
        }
      }
   
      // Adding click event listener
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
}, [ref]);
}

  return (
    <>
    <section className='content-details'>
    <div className={ loader ? 'loader' : 'loader hide'}><div className='spin'/></div>
    <div className='container'>
    <div className='row'>
        <div className={!subscription_check ? 'col-md-9':'col-md-12'}>
            {!hidebannerbox ? 
            <img src={static_url+'/'+awsresourceId+'/'+persons.landscapePosterIdNormal} ref={bannerbox} className='tpbutton' onClick={()=>assign(persons.trailerFileUrl,persons.title)}/>
           :''}
            {!switcher ?'': 
           <div className='reactsmallplayer'>
              {trailerUrl ? <TrailerPlayback url={trailerUrl}/>:''}
            </div>}
            {/*<img src={play} id="play" onClick={()=> assign(persons.trailerFileUrl ,persons.title)}></img>*/}
                    <p className='heading'>Hunt Original</p>
                    <h1>{persons.title}</h1>
                    <p>{persons.description}</p>
                    <p><span style={{color:'red'}}>Director :</span> <span>{persons.director}</span></p>
                    <p><span style={{color:'red'}}>Casts :</span> {persons.actors.map((c,index) => { 
                    if( persons.actors.lastIndexOf(c) !== persons.actors.length-1 ){
                        return (
                            <span key={index}> { c + ' ,'}</span>
                            ) 
                    }else{
                        return (
                            <span key={index}>{' '+ c}</span>
                            )
                    }
                   })}</p>
                   <ul>
                        <li>{persons.ageGroup}</li>
                        {genre.map((x,index)=>{
                            return <li key={index}>{x}</li>
                        })}
                    </ul>
                    <div className='buttons'>
                        <button className="playbtn" onClick={()=> handleepisode(episode)} ><i className="fa fa-play"></i> <span>Watch Full Web Series</span> </button>
                </div>
        </div>
       
        <div className={!subscription_check ? 'col-md-3 new_subs':'trail'}>
            <h2>Watch Full Episodes</h2>
            <p>Subscribe a plan to watch all web series</p>
        {array.map((x,index)=>{
            return (
                <>
                <div className='package' key={index}>
                    <p className='per'>{x.price.map(z => { return(<>  <img src={ruppee}/> { z.value/100 } </>)})} <input type='radio' value={x.name} name="choose" onChange={handleradio}/></p> 
                    <p>For {x.period} Days</p>
                </div>
                </>
            )
        })}
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.app.huntcinema&hl=en_IN&gl=US"><button className='btn_buy'> Subscribe Now</button> </a>
        </div>

       
    </div>
     {/*season travelled*/}
     <div className='col-md-12'>
        {flag ?
        <>
        <Select
            className='select'
            value={selectedOption}
            defaultValue={selectedOption}
            onChange={handle}
            options={options}
            classNamePrefix="react-select"
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? 'grey' : 'red',
                }),
              }}
        />
        <div className='season'>
        { seas ? 
        child.episodes.map((x,index) => { 
            return ( 
                
                <div className='episode' key={index}>
                <img src={static_url+'/'+awsresourceId+'/'+x.landscapePosterId} key={index} onClick={()=> handleepisode(x)} id="epi"/>
                <p>{x.name}</p>
                </div>
            )
            })
        :''
        } 
        </div>
        </>:''}
        </div>
    </div>
    </section>



    
    <Download/>
    <section className="product">
    <div className="container">
        <div className="product_category">
            <div className="product_category_heading">
                <h4>HUNT CINEMA ORIGINALS</h4>
                {/*<span> <i className='fa fa-arrow-left' onClick={() => slide(-1340)}></i><i className='fa fa-arrow-right' onClick={() => slide(+1340)}></i></span>*/}
            </div>
            <div className="product_item_list">
            {
            allContents.map( (x,index) => {
                if(x.test.ageGroup < 18){
                    return(
                        <div key={index}>
                                <a href={`/contents/${x.test.title}`} className="product_item">
                                    <div className="product_item_image">
                                        <img src={static_url+'/'+x.test.awsStaticResourcePath +'/'+x.test.portraitPosterIdNormal} alt=""/>
                                        <div className="product_item_image_overlay">
                                            <div className="play_icon"></div>
                                        </div>
                                        <p>{x.test.title}</p>
                                    </div>
                                </a>
                        </div>
                    )
                }
            })}
            </div>
         </div>
    </div>
    </section>
    </>
  )
}

export default Contentdetails