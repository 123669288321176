import React, {useState}  from 'react'
import { useNavigate ,useParams} from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import logo from '../../assets/image/Hunt.png';
import facebook from "../../assets/image/facebook_icon.png";
import google from "../../assets/image/google_icon.png";
import password from "../../assets/image/input_field_password.png";
import user from "../../assets/image/input_field_user.png";

const Resetpassword = () => {
   const navigate= useNavigate()
    const search = new URLSearchParams(window.location.search)
    const message = search.get("message");
    const email1  = search.get("email");
    const error = search.get("error")
    const {handleFacebookSignIn,handleGoogleSignIn,Reset} = useUserAuth();
    const [newpassword,setnewpassword]= useState('');
    const [cnew,setcnew]= useState('');
    const [email,setemail]= useState(email1);
    const [alert,setalert]=useState('')
    const [flag ,setflag]=useState(false)

    const reset=async(e)=>{
        e.preventDefault();
        setflag(true)
        if(newpassword === cnew){
            const data = {newPassword:newpassword,confirmPassword:cnew,email:email}
            const result = await Reset(data)
            console.log(result)
            setalert(result.message)
            setTimeout(()=>{
                if(result.error === false){
                    navigate('/login')
                }
            },[3000])
        }else{
            setalert('password not matching')
        }
       
    }
  return (
    <>
    <section className="login">
    <div className='logg'>
      <p style={{'color':'#fff','textAlign':'center','color':'red'}}>{alert}</p>
      <h1>RESET PASSWORD</h1>
      <p>reset your password now !</p><br/>
        <input disabled required type="text" value={email}  placeholder="Enter Your Email" />
        <input required type="text" onChange={(e)=>setnewpassword(e.target.value)} placeholder="New Password" />
        <input required type="text" onChange={(e)=>setcnew(e.target.value)} placeholder="Confirm Password" />
        <button className='playbtn' onClick={reset}>Reset</button>
        <div className="or_devider"><span>OR</span></div>
        <ul className="social_connect">
            <li><a href="/" onClick={handleFacebookSignIn}><span><img src={facebook} alt=""/></span> Facebook</a></li>
            <li><a href="/" onClick={handleGoogleSignIn}><span><img src={google} alt=""/></span> Google</a></li>
        </ul>
    </div>
    </section>
    </>
  )
}

export default Resetpassword