import React, { useEffect, useState } from 'react'
import { useParams ,Link, useNavigate} from 'react-router-dom'
import {Access} from '../../services/Access';
import play from "../../assets/Icons/Play Store.png";
import app from "../../assets/Icons/app store.png";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useUserAuth } from '../../context/UserAuthContext';
const Getuserdetails = () => {
  let {userId} = useParams();
  let navigate = useNavigate();
  const { logout,fetch } = useUserAuth();
  const [col,setcollection]=useState('')
  const [loader,setloader]=useState(false)
  const [flag,setflag]=useState(false)
  const [end,setend]=useState('')
  const [permission ,setpermission]=useState(false)
  var popping = localStorage.getItem('pop');
  const subscription_check = localStorage.getItem('subscriptionEnabled')
  useEffect(()=>{
    fetch()
    user_details()
    if(popping === 'false'){
      localStorage.removeItem('pop');
  }
  },[])

  const returnstart=(end)=>{
    var date2 = new Date(end)
    const map_end = date2.toLocaleDateString().split('/')
    const real_end = map_end[1]+'/'+ map_end[0] +'/'+map_end[2]
    setend(real_end)
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user_details=()=>{
    if(userId){
      const data = {
        'userId':userId
      }
      Access.post('/getProfile',data)
      .then(res => {
        if(res.data.code === 1002){
          //logout()
          //navigate('/login/error?message=please login again , session expired !')
        }
        if(res.data.error === false){
          const pre = res.data.data;
          setcollection(pre)
          if(pre.subscription_enddate !== 0 && pre.subscription_type !== 'free'){
            setflag(true)
            returnstart(pre.subscription_enddate)
            if(!subscription_check){
              localStorage.setItem('subscriptionEnabled',true)
            }
          }else{
              localStorage.removeItem('subscriptionEnabled')
          }
          if(res.permission){
            if(res.permission[0] === 'google' || res.permission[0] === 'facebook' || res.permission[0] === 'whatsapp'){
              setpermission(true)
            }
          }
        }
      }).catch(err=>{
        if(err.response && err.response.status === 403){
          logoutnew()
        }
      })
    }
  }
  const logoutnew=async()=>{
    setloader(true)
    const res = await logout();
    if(res){
      navigate('/login')
      setloader(false)
    }
  }
  return (
    <>
    <section className='getuser contactus'>
    <div className={ loader ? 'loader' : 'loader hide'}>
      <div className='spin'/>
    </div>
    <div className="container">
    <h1>MY PROFILE</h1>
    <p>Hunt offers you a wide collection of movies and shows to choose from in HD.</p>
    <img id="user" src={col.profilePic}/>
    
    <p className='username'>{col.name ? col.name : col.mobile}  
    {permission ? <i className='fa fa-cog'  style={{'paddingLeft':'10px','fontSize':'18px','cursor':'pointer'}} onClick={handleClick}></i>:''} <br/>
    {col.email}</p>
      <Menu
      style={{'marginLeft':'35px','marginTop':'0px'}}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={handleClose}><Link to={"/my-profile/update/"+ userId}>Update Profile</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to={"/my-profile/update/resetpassword/"+ userId}>Change Password</Link></MenuItem>
        </Menu>
            {flag ?
            <p style={{'lineHeight':'50px'}}className='username'>Subscription Validity - {end}</p> :
            <p className='username'>Account Type - Free <span className="upgrade"><a href={`/subscriptions/plans`}>Buy Plan</a></span></p>}
            <hr/>
            <h3>Download Our App</h3>
            <div className='images'>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.app.huntcinema&hl=en_IN&gl=US"><img src={play}/></a>
            <a target="_blank" href="#"><img src={app}/></a>
            </div>
    <button onClick={()=>{logoutnew()}}>Logout</button>
    </div> 
    </section>
    </>
  )
}

export default Getuserdetails