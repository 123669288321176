import React from 'react'
import Question from '../Contents/Question'
import Quote from '../Contents/Quote'

const Faqs = () => {
  return (
    <>
     <div className='faq'>
        <Question/>        
    </div>
    </>
  )
}

export default Faqs