import React from 'react'

const Question = () => {
    const increase=(i)=>{
        document.getElementsByClassName('hidden')[i].style.height = "auto";
        document.getElementsByClassName('dec')[i].style.display="block";
        document.getElementsByClassName('inc')[i].style.display="none";
    }
    const decrease=(i)=>{
        document.getElementsByClassName('hidden')[i].style.height = "0px";
        document.getElementsByClassName('inc')[i].style.display="block";
        document.getElementsByClassName('dec')[i].style.display="none";
    }
  return (
    <>
    <section className='frequent'>
    <h1>Frequently Asked Questions</h1>
    <div className="row">
        <div className='col-md-8'>
            <div className='freq-child'>
            <span>How Can I create an account on Hunt and login into it? </span> 
            <i className='fa fa-plus inc' onClick={()=> increase(0)}></i>
            <i className='fa fa-minus dec' onClick={()=> decrease(0)}></i>
            </div>
            
            <div className='hidden'>
                <p>If you’re accessing Hunt on a Desktop/App, click on Sign Up or by the social icon "Login with Google" or "Login with Facebook", this will automatically create your account.
                     Since you have logged in through your social profile hence NO PASSWORD is needed. Just remember your login account email id.
                </p>
            </div>

            <div className='freq-child'>
            <span>How Can I create an account on Hunt and login into it? </span> 
            <i className='fa fa-plus inc' onClick={()=> increase(1)}></i>
            <i className='fa fa-minus dec' onClick={()=> decrease(1)}></i>
            </div>
            
            <div className='hidden'>
                <p>If you’re accessing Hunt on a Desktop/App, click on Sign Up or by the social icon "Login with Google" or "Login with Facebook", this will automatically create your account.
                     Since you have logged in through your social profile hence NO PASSWORD is needed. Just remember your login account email id.
                </p>
            </div>

            <div className='freq-child'>
            <span>How Can I create an account on Hunt and login into it? </span> 
            <i className='fa fa-plus inc' onClick={()=> increase(2)}></i>
            <i className='fa fa-minus dec' onClick={()=> decrease(2)}></i>
            </div>
            
            <div className='hidden'>
                <p>If you’re accessing Hunt on a Desktop/App, click on Sign Up or by the social icon "Login with Google" or "Login with Facebook", this will automatically create your account.
                     Since you have logged in through your social profile hence NO PASSWORD is needed. Just remember your login account email id.
                </p>
            </div>
            <div className='freq-child'>
            <span>How Can I create an account on Hunt and login into it? </span> 
            <i className='fa fa-plus inc' onClick={()=> increase(3)}></i>
            <i className='fa fa-minus dec' onClick={()=> decrease(3)}></i>
            </div>
            
            <div className='hidden'>
                <p>If you’re accessing Hunt on a Desktop/App, click on Sign Up or by the social icon "Login with Google" or "Login with Facebook", this will automatically create your account.
                     Since you have logged in through your social profile hence NO PASSWORD is needed. Just remember your login account email id.
                </p>
            </div>
        </div>
        <div className='col-md-4'>
            <div className='ask'>
                <p>Have a Question ?</p>
                <h1>Didn't get your answer on frequently asked question ?</h1>
                <button className='playbtn'>Contact Us Now</button>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Question