import React, {useEffect,useState } from 'react'
import { useUserAuth } from '../context/UserAuthContext'
import right from '../assets/elements/Tick.png'
import ruppee from '../assets/image/membership_cut_price.png'
import Download from '../components/Contents/Download'
import { add_to_cart } from '../context/Eventlogger'
import Install from './Contents/Install'
import Instance from '../services/Base'
const Listpackage = () => {
    const ls = localStorage.getItem('deeplink')
    const { getPackage, allSubscriptions, Check_Subscription} = useUserAuth();
    const [array,setarray]=useState([])
    useEffect(()=>{
        Check_Subscription()
        const pack=async()=>{
            //const data =  await getPackage();
            //console.log(data)
            //setarray(data)
        }
        pack()
    },[])


  return (
    <>
    <div className='container subs'>
        <div className='row'>
          <div className='col-md-12'>
             {allSubscriptions[0].subscription.map((x,index) =>{
            return(
              <>
                <div className='col-md-3 package' key={index}>
                  <p className='per'> {x.price.map(z => { return(<>  <img src={ruppee}/> { z.value/100 } </>)})}</p> 
                    <p>{x.name} - {x.period}</p>
                    <div className='listb'>
                    {x.benefits.map((x,index)=>{
                      return <li key={index}><i className='fa fa-check'></i> {x.benifitsName}</li>
                    })}
                    </div>
                    <a href={'/subscriptions/payments/modes/active/'+ x._id}><button className='btn_buy'> BUY NOW</button> </a>
                </div>
                </>
            )
           })}
          </div>
          
           
           
        </div>
        
    </div>
    <br/>
    <br/>
    <Download/>
    <br/>
    <br/>
    <br/>
    {ls ? '':
<Install/>
}
    </>
  )
}

export default Listpackage