import React from 'react'
import footer from '../assets/image/Hunt.png';
import '../App.css';
const Footer = () => {
  return (
    <footer>
    <div className="container">
        <div className="row footer_area p-4">
            <div className="col-md-4">
                <a href="/" className="footer_logo"><img src={footer} alt=""/></a>
                <div className="footer_download mt-3">
                <ul className="social_icon">
                    <li><a target="_blank" href="https://www.facebook.com/profile.php?id=100088986791666&mibextid=ZbWKwL"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.youtube.com/channel/UCIaB3qiviZLmIrhze61QRRQ"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/p/Cob65l4vBcu/?igshid=MDM4ZDc5MmU="><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://twitter.com/HuntcinemaApp?t=nvDXz23t5KAooNl_SrH-tA&s=09"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                </ul>
                </div>
            </div>
            <div className="col-md-4">
                <h2>Quick Links</h2>
                <ul className="quick_link">
                    <li><a href="/login">Login/Signup</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                    <li><a href="/aboutUs">About Us</a></li>
                    <li><a href="/faqs">FAQs</a></li>
                </ul>
            </div>
            <div className="col-md-4">
                <h2>Legal</h2>
                <ul className="quick_link">
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                    <li><a href="/termsconditions">Terms  Conditions</a></li>
                    <li><a href="/refundpolicy">Refund & Cancellation</a></li>
                </ul>
            </div>
        </div>
        <div className="last_footer">
            <p>© 2020 All Rights Reserved to <span>HUNT CINEMA PRIVATE LIMITED</span> This website is owned by  <span>HUNT CINEMA PRIVATE LIMITED</span><br/>
            <span style={{'opacity':'.5','color':'#fff','fontSize':'13px','fontWeight':'normal'}}>-- Build- v3.0.5</span></p>
        </div>
    </div>
</footer>
  )
}

export default Footer