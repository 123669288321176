import React from 'react'
import Download from '../Contents/Download'
import about from '../../assets/Icons/About Us Img.png';
const Aboutus = () => {
  return (
    <>
    <section className="inner_about">
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
          <h1>About Hunt Cinema</h1>
          <p>Hunt Cinema is a video streaming service that offers a wide variety of genres from drama, horror, suspense, 
            thriller to comedy & beyond. Binge watch from our collection of web series, movies,Hunt Cinema Originals and 
            more in your regional language. Enjoy unlimited video streaming and downloads at 
            a pocket friendly price all year long.</p>
            <div className='companyd'>
                <p>Address : Hunt Cinema Private Limited, C/O PRAKASH VEER JANSATH ROAD; ALMASPUR; MUZAFFARNAGAR; Muzaffarnagar; Uttar Pradesh; 251001</p>
                <p>Contact Number : +91 9870771816</p>
                <p>Support Email : huntcinemaofficial@gmail.com</p>
                </div>
          </div>
          <div className='col-md-6'>
            <img src={about}/>
          </div>
        </div>
      </div>
    </section>
    <Download/>
    </>
  )
}

export default Aboutus