import React,{useState,useEffect} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import { Pagination,Navigation } from "swiper";
import { upcoming_trailer, view_item } from '../../context/Eventlogger';
import watch from "../../assets/Icons/watchbtn.png";
import trailer from '../../assets/Icons/playtrailer.png';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useUserAuth } from '../../context/UserAuthContext'
const static_url = process.env.REACT_APP_CDN_STATIC;
const Upcomingslider = () => {
  const {allUpcomings} = useUserAuth()
  const [date,setdate]=useState('')
  const [index,setindex]=useState(0)
  const [flag,setflag]=useState(false)
  const navigate = useNavigate();
  useEffect(()=>{
    if(window.innerWidth < 600){
      setflag(true)  
    }
  })
const validate=(date)=>{   
    const epoch = new Date(date).toDateString()
    return (
      <p>{epoch}</p>
    )}
    const appenddata=(e)=>{
      setindex(e.realIndex)
}
const assign=(contentId,title,url)=>{
  upcoming_trailer();
  navigate('/trailer/play/'+title+'/url?&&id='+url+'&&contentId='+contentId+'&&type=upcoming_trailer')
}
  return (
    <div className='upcoming'>
    <Swiper
      loop={true}
      slidesPerView={!flag ? 2 : 1}
      centeredSlides= {true}      
      navigation={true} 
      modules={[Navigation]}
      spaceBetween={15}
      onSlideChange={(e) => appenddata(e)}
    >
      {allUpcomings.map((x,index)=>{
        return(
          
          <SwiperSlide key={index}>
            <div className='upinner'>
              <div className='upinnerch'>
                <img src={static_url +'/' +x.test.resourcePath+'/'+x.test.landscapeNormal} onClick={()=>assign(x.test._id,x.test.title , x.test.mediaUrl.web)}/>
              </div>
              <div className='upinnerch'>
                  {x.test.isReleasePlanned ? <span className='date'>{validate(x.test.releaseDate)}</span>:''}
                  <h2>{x.test.title}</h2>
                  <p className='ds'>{x.test.description}</p>
                  <p>Casts : {x.test.cast.map((c,index) => { 
                    if( x.test.cast.lastIndexOf(c) !== x.test.cast.length-1 ){
                        return (
                            <span key={index}> { c + ' ,'}</span>
                            ) 
                    }else{
                        return (
                            <span key={index}>{' '+ c}</span>
                            )
                    }
                   })}</p>
                     <div className='buttons'>
                        <img src={trailer} onClick={()=>assign(x.test._id,x.test.title , x.test.mediaUrl.web)}/>
                        <img src={watch}/>
                    </div>

              </div>
            </div>
          </SwiperSlide>
          
        )
      })}
    </Swiper>
    <div className='bottom'>
      <p>{ index+1 } /{allUpcomings.length}</p>
      <span className='line'></span>
    </div>
    </div>
      
  )
}

export default Upcomingslider