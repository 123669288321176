import { analytics}  from "../firebase";
import { logEvent } from "firebase/analytics";
import ReactPixel from 'react-facebook-pixel';
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
ReactPixel.init('1506723346397955', options);

const upcoming_trailer =(title)=>{
    const data = {
        client:'web',
        name:title
    }
    logEvent(analytics,'upcoming_trailer',data)
    ReactPixel.trackCustom('upcoming_trailer',data); 
}
const deep_link=(title)=>{
    const data ={client:'web', name:title}
    logEvent(analytics,'deep_link',data)
    ReactPixel.trackCustom('deep_link',data); 
}
const click_poster=(title)=>{
    const data ={client:'web', name:title}
    logEvent(analytics,'click_poster',data)
    ReactPixel.trackCustom('click_poster',data); 
}
const checkout_paytm=(price,currency)=>{
    const data = {client:'web', price:price , currency:currency}
    logEvent(analytics,'checkout_paytm',data)
    ReactPixel.trackCustom('checkout_paytm',data); 
}
const checkout_stripe=(price,currency)=>{
    const data = {client:'web', price:price , currency:currency}
    logEvent(analytics,'checkout_stripe',data)
    ReactPixel.trackCustom('checkout_stripe',data); 
}
const begin_checkout=(price,currency)=>{
    const data = {client:'web', price:price , currency:currency}
    logEvent(analytics,'begin_checkout',data)
    ReactPixel.track('InitiateCheckout',data); 
}
const purchase=(orderId,txnid)=>{
    const data  = {client:'web', orderId:orderId,transaction_id:txnid}
    logEvent(analytics,'purchase',data)
    ReactPixel.trackCustom('purchase',data); 
}
const view_item=(title)=>{
    const data = {client:'web', title:title}
    logEvent(analytics,'view_item',data)
    ReactPixel.trackCustom('view_item',data); 
}
const login=(mode)=>{
    const data = {client:'web', type:mode}
    logEvent(analytics,'login',data)
    ReactPixel.trackCustom('login',data); 
}
const sign_up=(mode)=>{
    const data = {client:'web', type:mode}
    logEvent(analytics,'sign_up',data)
    ReactPixel.track('CompleteRegistration',data); 
}
const watch_trailer=()=>{
    const data = {client:'web'}
    logEvent(analytics,'watch_trailer',data)
    ReactPixel.trackCustom('watch_trailer',data);
}
const upgrade_package=()=>{
    logEvent(analytics, 'upgrade_package' ,{client:'web'})
    ReactPixel.trackCustom('upgrade_package',{client:'web'});
}
const add_to_cart=(name)=>{
    logEvent(analytics, 'add_to_cart' ,{client:'web',name:name})
    ReactPixel.track('AddToCart',{client:'web',name:name});
}
const purchase_new=(data)=>{
    logEvent(analytics, 'purchase_new' ,{client:'web',data:data})
    ReactPixel.trackCustom('purchase_new',{client:'web',data:data});
}
const purchase_stripe=(data)=>{
    logEvent(analytics, 'purchase_stripe' ,{client:'web',data:data})
    ReactPixel.trackCustom('purchase_stripe',{client:'web',data:data});
}
const purchase_paytm=(data)=>{
    logEvent(analytics, 'purchase_paytm' ,{client:'web',data:data})
    ReactPixel.trackCustom('purchase_paytm',{client:'web',data:data});
}
export {upcoming_trailer,deep_link,checkout_paytm,checkout_stripe,
    begin_checkout,purchase,view_item,click_poster,login,sign_up,
    watch_trailer,upgrade_package,add_to_cart,purchase_new,purchase_stripe,purchase_paytm}