import React,{useEffect,useState,useRef} from 'react';
import Homeslider from './Slider/Homeslider';
import Trailer from './Slider/Trailer';
import { useUserAuth } from '../context/UserAuthContext'
import Download from './Contents/Download';
import Newsletter from './Contents/Newsletter';
import Question from './Contents/Question';
import Install from './Contents/Install';
import playStore from '../assets/image/playstore.svg'
import { upcoming_trailer, view_item } from '../context/Eventlogger';
import banner from '../assets/image/homescreen.png'
const static_url = process.env.REACT_APP_CDN_STATIC;
const Home = ({props}) => {
const {fetch,allContents,refresh} = useUserAuth()
const scrl1=useRef()
const scrl = useRef()
const [Content,setContent]  =useState([])
const [loader,setloader]=useState(false)
var popping = localStorage.getItem('pop');
const [data,setdata]=useState([]);
var new_arr=[];

useEffect(() => {
    fetch()    
    hit()
    if(popping === 'false'){
        localStorage.removeItem('pop');
    }
}, [])

const ls = localStorage.getItem('deeplink')
const hit=()=>{
    if(allContents.length === 0){
        setloader(true)
        refresh()
    }else{
        setloader(false)
        allContents.map(x=>{
            if(x.test.ageGroup < 18){
              new_arr.push(x)
            }
          })
        setdata(new_arr)
    }
    callback(new_arr)
}

const movies = []
const arr = []

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
   }

const callback=(data)=>{
    data.map(x => x.test.subcategory.map(x=> movies.push(x)))
    //data.content.map(x => x.subcategory.map(x=> movies.push(x) ))
    const uniq = movies.filter(onlyUnique)
    for(var i=0;i<uniq.length;i++){
        //only checking first value of an subcategory array for filteration || uniq[i] === 'Hunt Original' || uniq[i] === 'Action'
        if(uniq[i] === 'Hunt Non Exclusive' ){
            const result = data.filter(x=> x.test.subcategory[0] === uniq[i]  )
            var key = uniq[i]
            if(result.length > 0){            
                    if(key == 'Hunt Non Exclusive'){
                        arr.push({name: key ,value:result})
                    }
                }
        }
       
    }
    setContent(arr)
}


//sliding buttons
const slide = (shift) => {
scrl.current.scrollLeft += shift;
};
const slide1 = (shift) => {
scrl1.current.scrollLeft += shift;
};
  

return (
<>  
<div className={ loader ? 'loader' : 'loader hide'}>
<div className='spin'/>
</div>
<section className="banner">
    <div className="banner_col1">
    {/*<Homeslider/>*/}
    <img src={banner}/>
    <div className='banner_btm'>
    <h2>India's No. 1 OTT King Hunt Cinema. To Watch Unlimited Webseries and Movies Donwload Hunt Cinema from Playstore.</h2>
    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.huntcinema.android.pro"><img src={playStore}/></a>
    </div>
    </div>
</section>
<section className='banner trailer'>
    <h1>TOP 12 MOVIE THIS MONTH</h1>
    <p>watch top 12 movie this month</p>
        <div className='banner_col'>
        <Trailer/>
        </div>
</section>
<Download/>
{/*for dynamic making of category based contents */}
{
    Content.map((x,index)=>{
        return(
            <section className="product" key={index}>
            <div className="container">
                <div className="product_category">
                    <div className="product_category_heading">
                    <h4>{x.name}</h4>
                    </div>
                    <div className="product_item_list">
                        { x.value.map((y,index)=>{
                            if(y.test.ageGroup){
                                return(
                                    <div key={index}>
                                    <a onClick={()=> view_item(y.test.title)} href={'/contents/'+`${y.test.title}`} className="product_item">
                                                <div className="product_item_image" >
                                                    <img src={static_url+'/'+y.test.awsStaticResourcePath +'/'+y.test.portraitPosterIdNormal} alt=""/>
                                                    <div className="product_item_image_overlay">
                                                        <div className="play_icon"></div>
                                                    </div>
                                                    <p>{y.test.title}</p>
                                                </div>
                                            </a>
                                    </div>
                                )
                            }
                           
                        })}
                    
                    </div>
                </div>
            </div>
        </section>
            
        )
    })
}

<Newsletter/>
<Question/>
{ls ? '':
<Install/>
}
</>
)
}

export default Home