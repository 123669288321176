import React,{useRef,useState,useEffect} from "react";
import { useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import './Player.css'
import PlayerControlstrailer from "./PlayerControlstrailer";
function TrailerPlayback(props) {
  let {title} = useParams()
  const search = new URLSearchParams(window.location.search)
  const contentId = search.get("contentId");
  const playerRef = useRef()
  const playerContainerRef= useRef()
  const controlRef = useRef()
  const [hlsUrl,sethlsUrl]=useState('')
  const [trackLang, setTrackLang] = useState('');
  const [upcoming,setupcoming]=useState(false)
  useEffect(()=>{
      sethlsUrl(props.url)
      setTimeout(()=>{
        setstate({...state, playing: !state.playing})
      },[500])
      setTimeout(()=>{
        controlRef.current.style.visibility="hidden"
      },[1500])
      //watch_trailer()
  },[])

  const [state,setstate] = useState({
    playing:false,
    muted:true,
    playbackRate:1.0,
    played:0,
    seeking:false,
    pip:false
  })


  const [buff,setBuff]=useState(false)
  const handleplaypause=()=>{
    setstate({...state, playing: !state.playing})
  }
  const handleforward=()=>{
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
  }
  const handlebackward=()=>{
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
  }
  const handlemute=()=>{
    setstate({...state, muted:!state.muted})
  }

  const handleplaybackratechange=(rate)=>{
    setstate({...state, playbackRate : rate })
  }

  const toggleFullScreen=()=>{
    screenfull.toggle(playerContainerRef.current)
  }
  const handleBuffer=()=>{
    setBuff(true)
  }
  const handleBufferEnd=()=>{
    setBuff(false)
  }

  var count = 0;
  const handleProgress=(changeState)=>{
    if(state.playing){
      controlRef.current.style.visibility="hidden"
    }
    if(controlRef.current.style.visibility === 'visible'){
      count+=1
    }
    if(count > 1){
      controlRef.current.style.visibility="hidden"
      count = 0
    }
    if(!state.seeking){
      setstate({...state, ...changeState})
    }
  }

  const handleSeekChange=(e,newValue)=>{
    setstate({...state,played:parseFloat(newValue/100)})
  }
  const handleSeekMouseDown=(e)=>{
    setstate({...state,seeking:true})
  }
  const handleSeekMouseUp=(e,newValue)=>{
    setstate({...state,seeking:false})
    playerRef.current.seekTo(newValue/100)
  }

    const format=(seconds)=>{
      if(isNaN(seconds)){
        return '00:00'
      }
      const date = new Date(seconds*1000)
      const hh = date.getUTCHours()
      const mm = date.getUTCMinutes()
      const ss = date.getUTCSeconds().toString().padStart(2,"0")
      if(hh){
        return `${hh}:${mm.toString().padStart(2,"0")}:${ss}`
      }
      return `${mm}:${ss}`
    }
  const currentTime = playerRef.current ? 
  playerRef.current.getCurrentTime() : '00:00'

  const duration = playerRef.current ? 
  playerRef.current.getDuration() : '00:00'

  const elapsedTime = format(currentTime)
  const totalDuration = format(duration)

  const handleMouseMove=()=>{
    controlRef.current.style.visibility="visible";
    count = 0
  }
  const handleVolumeChange=(e, newValue)=>{
    setstate({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });

  }
  const handleVolumeSeekDown=(e, newValue)=>{
    setstate({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  }



  const handleQuality=(quality , level)=>{
    //console.log(level)
    setstate({...state, playing: false})
    var hls = playerRef.current.getInternalPlayer('hls');
    if(quality === '-1'){
      hls.loadLevel = quality
      hls.subtitleTrack = level
    }else{
      hls.currentLevel = quality
      hls.subtitleTrack = level
    }
    //console.log(alllevel)
    //setstate({...state, playing: !state.playing})
    setstate({...state, playing: true})
  }
  const onTrackChange=(e)=>{
    //console.log(e)
    setTrackLang(e)
  }
  const handlePip=()=>{
    setstate({...state, pip:!pip})
  }
const{playing,muted,playbackRate,played,seeking,pip}=state  

  return (
    <div className="playerWrapper" ref={playerContainerRef} onMouseMove={handleMouseMove}>
          <ReactPlayer
            width={"100%"}
            height="100%"
            url={hlsUrl}
            pip={pip}
            muted={muted}
            playing={playing}
            controls={false}
            ref={playerRef}
            loop={true}
            playbackRate={playbackRate}
            onProgress={handleProgress}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
          />
          
          <PlayerControlstrailer
          upcoming={upcoming}
          playerRef={playerRef}
          contentId={contentId}
          ref={controlRef}
          onPlayPause={handleplaypause}
          playing={playing} 
          onforward={handleforward}
          onbackward={handlebackward}
          muted={muted}
          onmute={handlemute}
          playbackRate={playbackRate}
          onplaybackratechange={handleplaybackratechange}
          onToggleFullScreen={toggleFullScreen}
          played={played}
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          onVolumeChange={handleVolumeChange}
          onVolumeSeekDown={handleVolumeSeekDown}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
          Buffer={buff}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          title={title}
          onQuality={handleQuality}
          onTrack={onTrackChange}
          onPip={handlePip}
        //onDetail={handlePlayer}
        />
    </div>
  );
}

export default TrailerPlayback;